import Vue from "vue";

export const date = (stringdate) => {
  if (!stringdate) return "";

  let date = new Date();

  if (stringdate) date = new Date(stringdate);

  const [year, month, day] = date.toISOString().substr(0, 10).split("-");

  return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
};

export const time = (stringdate) => {
  if (!stringdate) return "";

  const date = new Date(stringdate);

  const hours = date.getHours().toString().padStart(2, "0");

  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getDateTime = (stringdate) => {
  if (!stringdate) return "";

  const date = new Date(stringdate);
  const year = date.getFullYear();

  const month = (date.getMonth() + 1).toString().padStart(2, "0");

  const day = date.getDate().toString().padStart(2, "0");

  const hours = date.getHours().toString().padStart(2, "0");

  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

Vue.prototype.$date = date;
Vue.filter("date", date);
Vue.prototype.$time = time;
Vue.filter("time", time);
Vue.prototype.$dateTime = getDateTime;
Vue.filter("dateTime", getDateTime);
