/*import store from "../store";

export default async (to, from, next) => {
  let user = store.state.auth.user;
  let isUser = !!user?.id;
  if (!isUser) {
    user = await store.dispatch("auth/INIT_USER");
  }

  if (user) {
    const menus = Array.isArray(user.menus) ? user.menus : [];
    next(menus.some((x) => x.route == to.path) || "/");
  } else {
    next("/");
  }
};*/

export default (to, from, next) => {
  next();
};
