<script>
import Chart from "@/components/Chart.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Chart },

  data: () => ({
    genderChart: {
      type: "pie",
      data: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#0096DB", "#FF0000"],
            data: [],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        rotation: 35,
      },
    },
    isGenderChart: false,

    dailyChart: {
      type: "line",
      backgroundColor: "rgba(0, 0, 0, 0.1)",

      data: {
        datasets: [
          {
            label: "Accepted",
            data: [],
            borderColor: "green",
            tension: 0.1,
          },
          {
            label: "Rejected",
            data: [],
            borderColor: "red",
            tension: 0.1,
          },
          {
            label: "To be review",
            data: [],
            borderColor: "blue",
            tension: 0.1,
          },
        ],
      },

      options: {
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    },
    isDailyChart: false,

    mapdata: {
      main_settings: {
        width: "responsive",
        background_color: "#FFFFFF",
        background_transparent: "yes",
        border_color: "black",

        //State defaults
        state_description: "State description",
        state_color: "#fff",
        state_hover_color: "#A6D2EA",
        state_url: "",
        border_size: 1.5,
        all_states_inactive: "no",
        all_states_zoomable: "no",

        //Location defaults
        location_description: "Location description",
        location_url: "",
        location_color: "#FF0067",
        location_opacity: 0.8,
        location_hover_opacity: 1,
        location_size: 25,
        location_type: "square",
        location_image_source: "frog.png",
        location_border_color: "#FFFFFF",
        location_border: 2,
        location_hover_border: 2.5,
        all_locations_inactive: "no",
        all_locations_hidden: "no",

        //Label defaults
        label_color: "#d5ddec",
        label_hover_color: "#d5ddec",
        label_size: 22,
        label_font: "Arial",
        hide_labels: "no",
        hide_eastern_labels: "no",

        //Zoom settings
        zoom: "no",
        manual_zoom: "no",
        back_image: "no",
        initial_back: "no",
        initial_zoom: "-1",
        initial_zoom_solo: "no",
        region_opacity: 1,
        region_hover_opacity: 0.6,
        zoom_out_incrementally: "no",
        zoom_percentage: 0.99,
        zoom_time: 0.5,

        //Popup settings
        popup_color: "white",
        popup_opacity: 0.9,
        popup_shadow: 1,
        popup_corners: 5,
        popup_font: "12px/1.5 Verdana, Arial, Helvetica, sans-serif",
        popup_nocss: "no",

        //Advanced settings
        div: "map",
        auto_load: "yes",
        url_new_tab: "no",
        images_directory: "default",
        fade_time: 0.1,
        link_text: "View Website",
        popups: "detect",
        state_image_url: "",
        state_image_position: "",
        location_image_url: "",
      },
      state_specific: {
        UZB356: {
          name: "Karakalpakstan",
          description: 0,
        },
        UZB355: {
          name: "Khorezm",
          description: 0,
        },
        UZB370: {
          name: "Jizzakh",
          description: 0,
        },
        UZB4828: {
          name: "Tashkent",
          description: 0,
        },
        UZB362: {
          name: "Surkhandarya",
          description: 0,
        },
        UZB371: {
          name: "Sirdaryo",
          description: 0,
        },
        UZB364: {
          name: "Ferghana",
          description: 0,
        },
        UZB365: {
          name: "Namangan",
          description: 0,
        },
        UZB361: {
          name: "Kashkadarya",
          description: 0,
        },
        UZB363: {
          name: "Andijon",
          description: 0,
        },
        UZB354: {
          name: "Bukhoro",
          description: 0,
        },
        UZB358: {
          name: "Samarkand",
          description: 0,
        },
        UZB372: {
          name: "Tashkent",
          description: 0,
        },
        UZB357: {
          name: "Navoi",
          description: 0,
        },
      },
      locations: {},
      labels: {},
      regions: {},
    },

    state_specific: [
      {
        code: "UZB356",
        name: "Karakalpakstan",
      },
      {
        code: "UZB355",
        name: "Khorezm",
      },
      {
        code: "UZB370",
        name: "Jizzakh",
      },
      {
        code: "UZB4828",
        name: "Tashkent",
      },
      {
        code: "UZB362",
        name: "Surkhandarya",
      },
      {
        code: "UZB371",
        name: "Sirdaryo",
      },
      {
        code: "UZB364",
        name: "Ferghana",
      },
      {
        code: "UZB365",
        name: "Namangan",
      },
      {
        code: "UZB361",
        name: "Kashkadarya",
      },
      {
        code: "UZB363",
        name: "Andijon",
      },
      {
        code: "UZB354",
        name: "Bukhoro",
      },
      {
        code: "UZB358",
        name: "Samarkand",
      },
      {
        code: "UZB372",
        name: "Tashkent",
      },
      {
        code: "UZB357",
        name: "Navoi",
      },
    ],

    branch: -1,
    isLoading: false,
  }),

  computed: {
    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,
    ...mapGetters("admin-statistics", [
      "GET_DAILYS",
      "GET_GENDERS",
      "GET_FACULTYS",
      "GET_REGIONS",
    ]),

    branches: (vm) => {
      const branches = vm.$toArray(vm.user.branches);
      branches.sort((a, b) => a.id - b.id);
      if (vm.user.role !== "ADMIN_SUPER") {
        vm.branch = vm.user.branches[0].id;
        return branches;
      } else {
        return [
          {
            id: -1,
            name: vm.$t("All branches"),
          },
          ...branches,
        ];
      }
    },

    tabBranch: {
      get: (vm) => vm.branches.findIndex((branch) => branch.id === vm.branch),
      set(v) {
        this.branch = this.branches[v].id;
      },
    },
  },

  beforeDestroy() {
    this.removeScript("countrymap_config");
    this.removeScript("countrymap");
  },

  mounted() {
    this.refresh();
  },

  methods: {
    ...mapActions("admin-statistics", [
      "INIT_DAILYS",
      "INIT_GENDERS",
      "INIT_FACULTYS",
      "INIT_REGIONS",
    ]),

    createDailyData(data, type) {
      return data.map((fl) => {
        const items = this.GET_DAILYS.filter((x) => x.status === type);
        return {
          x: fl,
          y: items.find((x) => this.toDate(x.date) === fl)?.quantity || 0,
        };
      });
    },

    toDate(fl) {
      const date = new Date(fl);
      const day = `${date.getDate()}`.padStart(2, "0");
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      return `${day}-${month}`;
    },

    addScript(id, src, text) {
      if (document.getElementById(id) || (!src && !text)) return;

      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      if (src) script.setAttribute("src", src);
      else script.textContent = text;
      script.setAttribute("id", id);
      document.head.appendChild(script);
    },

    removeScript(id) {
      const el = document.getElementById(id);
      if (el) el.remove();
    },

    refresh() {
      this.INIT_REGIONS(this.branch).then(() => {
        this.mapdata.state_specific = this.state_specific
          .map((key) => {
            const obj = {};

            const region =
              this.GET_REGIONS.find((r) => r.code === key.code) || {};

            obj[key.code] = {};
            obj[key.code].name = region.regionName || key.name;
            obj[key.code].description = region.quantity || 0;

            return obj;
          })
          .reduce((a, b) => Object.assign(a, b), {});

        this.addScript(
          "countrymap_config",
          null,
          `var simplemaps_countrymap_mapdata = ${JSON.stringify(this.mapdata)}`
        );
        this.addScript("countrymap", "/country-statistics/countrymap.js");
      });
      this.INIT_DAILYS(this.branch).then(() => {
        this.isDailyChart = false;

        const data = this.GET_DAILYS.map((x) => this.toDate(x.date)).filter(
          (x, index, self) => index === self.findIndex((t) => t === x)
        );

        this.dailyChart.data.labels = data;

        this.dailyChart.data.datasets[0].data = this.createDailyData(
          data,
          "ACCEPTED"
        );
        this.dailyChart.data.datasets[1].data = this.createDailyData(
          data,
          "REJECTED"
        );
        this.dailyChart.data.datasets[2].data = this.createDailyData(
          data,
          "SUBMITTED"
        );

        this.$nextTick(() => {
          this.isDailyChart = true;
        });
      });
      this.INIT_GENDERS(this.branch).then(() => {
        const genders = this.GET_GENDERS;
        this.isGenderChart = false;
        this.genderChart.data.labels = genders.map((x) => x.gender);
        this.genderChart.data.datasets[0].data = genders.map((x) => x.quantity);
        this.$nextTick(() => {
          this.isGenderChart = true;
        });
      });
      this.INIT_FACULTYS(this.branch);
    },
  },

  watch: {
    tabBranch() {
      this.$nextTick(() => {
        this.refresh();
      });
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs
          v-model="tabBranch"
          class="mb-3 pt-2 d-flex justify-center"
          style="border-radius: 0.3rem"
          backgroundColor="accent"
          v-if="branches"
          color="success"
        >
          <v-tab
            v-for="(b, index) of branches"
            class="transform-none text-uppercase font-weight-black mr-1"
            :key="index"
            :value="b.id"
          >
            {{ b.name }}
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col cols="12">
        <div class="-cart">
          <div
            class="title accent--text text-center pb-5"
            v-text="$t('Daily statistics')"
          />

          <div class="white">
            <chart v-if="isDailyChart" :data="dailyChart" />
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="pt-9">
        <div class="-cart">
          <div
            class="title accent--text text-center pb-5"
            v-text="$t('Gender information')"
          />

          <div class="g-chart">
            <chart v-if="isGenderChart" :data="genderChart" />
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="pt-9">
        <div class="-cart">
          <div
            class="title accent--text text-center pb-5"
            v-text="$t('Regional information')"
          />

          <div class="text-center">
            <div id="map" class="map_uzb" />
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="pt-9">
        <div class="-cart">
          <v-expansion-panels popout>
            <v-expansion-panel v-for="(item, i) in GET_FACULTYS" :key="i">
              <v-expansion-panel-header>
                <div class="d-flex w-100">
                  <div v-text="item.facultyName" />
                  <v-spacer />
                  <div class="pr-5 accent--text" v-text="$sum(item.quantity)" />
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  class="d-flex"
                  style="justify-content: space-between; flex-wrap: wrap"
                >
                  <div style="flex: 1; text-align: center" class="d-flex">
                    <div style="color: #0096db">
                      BOYS: {{ item.boysCount | sum }}
                    </div>
                    <div class="ma-1" />
                    <div style="color: #ff0000">
                      GIRLS: {{ item.girlsCount | sum }}
                    </div>
                  </div>
                  <div class="count-with-icon">
                    <v-icon color="#0096db">mdi-white-balance-sunny</v-icon>
                    {{ item.dayTimeEducationCount | sum }}
                  </div>
                  <div class="count-with-icon">
                    <v-icon color="#0096db">mdi-moon-waning-crescent</v-icon>
                    {{ item.eveningEducationCount | sum }}
                  </div>
                  <div class="count-with-icon">
                    <v-icon color="#0096db">mdi-lan</v-icon>
                    {{ item.eveningEducationCount | sum }}
                  </div>
                  <div class="count-with-icon">
                    <v-icon color="#0096db">mdi-link-variant-plus</v-icon>
                    {{ item.secondDegreeCount | sum }}
                  </div>
                  <div
                    style="
                      flex: 1;
                      text-align: center;
                      border-left: 1px solid #888888;
                    "
                  >
                    <div style="color: #ff0000">
                      ACCEPTED: {{ item.acceptedCount | sum }}
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.-cart {
  background: rgba(154, 154, 154, 0.2);
  border-radius: 10px;
  padding: 30px;
  height: 100%;
}

.g-chart {
  max-width: 276px;
  margin: 0 auto;
}

.count-with-icon {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: space-around;
  border-left: 1px solid #888888;
}
</style>
