<script>
import ModalsTemplate from "../ModalsTemplate.vue";

import { mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ModalsTemplate,
  },

  data: () => ({
    object: {
      id: null,
      names: {
        uz: null,
        ru: null,
        en: null,
      },
      active: true,
    },

    model: true,
  }),

  watch: {
    model() {
      setTimeout(() => {
        this.$emit("close");
      }, 100);
    },
  },

  created() {
    if (this.data.id) {
      this.object = JSON.parse(JSON.stringify(this.data));
    }
  },

  methods: {
    ...mapActions("admin-education-types", ["ADD_EDUCATION_TYPE"]),

    onSend() {
      if (this.$refs.form.validate()) {
        this.ADD_EDUCATION_TYPE(this.object).then((status) => {
          if (status != null) {
            this.$emit("refresh");
            this.model = false;
          }
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="650" v-model="model">
    <v-form @submit.prevent="() => false" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field
            autofocus
            autocomplete="off"
            v-model="object.names.uz"
            :label="$t('Name uz')"
            :rules="[$rules.required]"
            required
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="off"
            v-model="object.names.ru"
            :label="$t('Name ru')"
            :rules="[$rules.required]"
            required
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="off"
            v-model="object.names.en"
            :label="$t('Name en')"
            outlined
            hide-details
          />
        </v-col>
      </v-row>
    </v-form>

    <template slot="actions">
      <div class="d-flex w-100">
        <v-spacer />
        <v-btn color="primary" class="btn-accent" @click="onSend">
          {{ $t("Save") }}
        </v-btn>
      </div>
    </template>
  </modals-template>
</template>
