<script>
import ModalsTemplate from "../ModalsTemplate.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ModalsTemplate,
  },

  data: (vm) => ({
    object: {
      id: null,
      firstName: null,
      lastName: null,
      middleName: null,
      branches: [],
      phone: null,
      password: null,
      role: null,
    },

    confirmPassword: null,

    model: true,

    confirmPasswordRules: [
      vm.$rules.required,
      (v) =>
        v === vm.object.password ||
        vm.$t("The password confirmation does not match."),
    ],
  }),

  watch: {
    model() {
      setTimeout(() => {
        this.$emit("close");
      }, 100);
    },
  },

  created() {
    this.INIT_ROLES();
    this.INIT_ADMIN_BRANCHES();
    if (this.data?.id) {
      this.object = JSON.parse(JSON.stringify(this.data));
    }
  },

  computed: {
    ...mapGetters("admin-member", ["GET_ADMIN_BRANCHES", "GET_ROLES"]),
    pickBranches: (vm) => vm.GET_ADMIN_BRANCHES,
    roles: (vm) => {
      return vm.GET_ROLES.map((item) => {
        return {
          id: item.id,
          name: vm.$t(item.name),
        };
      });
    },
    pickedBranches: {
      get() {
        return this.object.branches.map((item) => item.id);
      },
      set(value) {
        this.object.branches.length = 0;

        value.map((item) => {
          this.object.branches.push(
            this.pickBranches.find((i) => i.id === item)
          );
        });
      },
    },
  },

  methods: {
    ...mapActions("admin-member", [
      "ADD_MEMBER",
      "INIT_ADMIN_BRANCHES",
      "INIT_ROLES",
    ]),
    onSend() {
      if (this.$refs.form.validate()) {
        this.ADD_MEMBER(this.object).then((status) => {
          if (status != null) {
            this.$emit("refresh");
            this.model = false;
          }
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :title="$t('Admins')" :width="650" v-model="model">
    <v-form @submit.prevent="() => false" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field
            autofocus
            autocomplete="off"
            v-model="object.firstName"
            :label="$t('First name')"
            :rules="[$rules.required]"
            required
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="off"
            v-model="object.lastName"
            :label="$t('Last name')"
            :rules="[$rules.required]"
            required
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="off"
            v-model="object.middleName"
            :label="$t('Middle name')"
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            autocomplete="off"
            :label="$t('Phone number')"
            outlined
            v-model="object.phone"
            required
            :rules="[$rules.required]"
            v-inputmask="$masks.number2"
            hide-details
          />
        </v-col>
        <template v-if="!object.id">
          <v-col cols="12">
            <v-text-field
              autocomplete="off"
              :label="$t('Password')"
              outlined
              v-model="object.password"
              required
              :rules="[$rules.required]"
              hide-details
            />
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-text-field
              autocomplete="off"
              :label="$t('Confirm password')"
              outlined
              required
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
            />
          </v-col>
        </template>
        <v-col cols="12" class="pt-0">
          <v-select
            autocomplete="off"
            v-model="object.role"
            :items="roles"
            item-text="name"
            item-value="id"
            min="1"
            :rules="[$rules.required]"
            :label="$t('Role')"
            required
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-select
            multiple
            autocomplete="off"
            v-model="pickedBranches"
            :items="pickBranches"
            item-text="name"
            item-value="id"
            :rules="[$rules.required]"
            :label="$t('Branches')"
            required
            outlined
            hide-details
          />
        </v-col>
      </v-row>
    </v-form>

    <template slot="actions">
      <div class="d-flex w-100">
        <v-spacer />
        <v-btn color="primary" class="btn-accent" @click="onSend">
          {{ $t("Save") }}
        </v-btn>
      </div>
    </template>
  </modals-template>
</template>
