<script>
import AuthTemplate from "@/components/AuthTemplate.vue";

import { mapActions } from "vuex";
import TelInputAuth from "@/components/TelInput/TelInputAuth.vue";

export default {
  name: "SignUp",

  components: { AuthTemplate, TelInputAuth },

  data: () => ({
    phone: null,

    isLoading: false,
  }),

  created() {
    const phone = this.$route.query.phone;

    if (Number(phone)) {
      this.phone = phone;
    }
  },

  methods: {
    ...mapActions("auth", ["SIGN_UP"]),

    onSend() {
      if (this.$refs.form.validate()) {
        const phone = this.phone;
        this.isLoading = true;

        this.SIGN_UP({ phone })
          .then((status) => {
            this.isLoading = false;
            if (status != null) this.$router.push(`/?phone=${this.phone}`);
          })
          .catch(() => (this.isLoading = false));
      }
    },
  },
};
</script>

<template>
  <div>
    <auth-template>
      <v-row>
        <v-col cols="12" md="6">
          <div class="-content">
            <div
              class="-title"
              v-text="$t(`Dear entrant, to register Enter your phone number:`)"
            />

            <div class="fields-wrap">
              <v-form @submit.prevent="() => false" ref="form" lazy-validation>
                <tel-input-auth
                  @enter="onSend"
                  v-model="phone"
                  required
                  :disabled="isLoading"
                  autofocus
                />
              </v-form>

              <div class="-actions">
                <div style="flex: 1" class="pr-sm-2">
                  <v-btn
                    :loading="isLoading"
                    @click="onSend"
                    :disabled="isLoading"
                    color="accent"
                    class="transform-none w-100"
                  >
                    {{ $t("Sign up") }}
                  </v-btn>
                </div>
                <div class="d-block d-sm-none pt-4" />
                <div style="flex: 1" class="pl-sm-2">
                  <v-btn
                    :to="`/?phone=${phone}`"
                    class="transform-none w-100"
                    :disabled="isLoading"
                    color="accent"
                  >
                    {{ $t("Enter") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" md="6">
          <img class="login-img" src="../../assets/img/logo.png" alt="" />
        </v-col>
      </v-row>
    </auth-template>
  </div>
</template>

<style lang="scss" scoped>
.-content {
  padding-top: 30px;
  padding-bottom: 50px;

  @media only screen and(min-width: 960px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  color: #004f73;

  max-width: 487px;
}

.fields-wrap {
  padding-top: 30px;
  max-width: 400px;

  .-actions {
    display: flex;

    @media only screen and(max-width:599px) {
      flex-direction: column;
    }
  }
}

.login-img {
  width: 55%;
  margin-left: 70px;
  margin-top: 30px;
  margin-bottom: 50px;
}
</style>
