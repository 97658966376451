<script>
import { mapActions, mapGetters } from "vuex";
import adminApplicationsList from "@/components/AdminApplicationsList.vue";

export default {
  components: { adminApplicationsList },

  data: () => ({
    tab: 0,
    branch: -1,
    isLoading: false,
    tabStatus: ["ALL", "SUBMITTED", "ACCEPTED", "REJECTED"],
  }),

  created() {
    this.INIT_COUNT();
  },

  computed: {
    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,
    branches: (vm) => {
      const branches = vm.$toArray(vm.user.branches);
      branches.sort((a, b) => a.id - b.id);
      if (vm.user.role !== "ADMIN_SUPER") {
        vm.branch = vm.user.branches[0].id;
        return branches;
      } else {
        return [
          {
            id: -1,
            name: vm.$t("All branches"),
          },
          ...branches,
        ];
      }
    },
    ...mapGetters("admin-applications", ["GET_COUNT"]),

    tabBranch: {
      get: (vm) => vm.branches.findIndex((branch) => branch.id === vm.branch),
      set(v) {
        this.branch = this.branches[v].id;
      },
    },
  },

  methods: {
    ...mapActions("admin-applications", ["INIT_COUNT"]),
  },
};
</script>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" dark color="#288FDA">
            <v-card-text v-if="GET_COUNT.ALL">
              <div class="card-title" v-text="$t('All applications')" />
              <div class="card-count">
                {{ GET_COUNT.ALL.TOTAL }}
              </div>
              <ul
                class="card-branch-count"
                v-if="GET_COUNT.ALL.BRANCHES.length > 0"
              >
                <li
                  v-for="{ id, name, count } in GET_COUNT.ALL.BRANCHES"
                  :key="id"
                  v-text="`${name}: ${count}`"
                />
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" dark color="#26d316">
            <v-card-text v-if="GET_COUNT.ACCEPTED">
              <div class="card-title">{{ $t("Accepted card") }}</div>
              <div class="card-count">
                {{ GET_COUNT.ACCEPTED.TOTAL }}
              </div>
              <ul
                class="card-branch-count"
                v-if="GET_COUNT.ACCEPTED.BRANCHES.length > 0"
              >
                <li
                  v-for="{ id, name, count } in GET_COUNT.ACCEPTED.BRANCHES"
                  :key="id"
                  v-text="`${name}: ${count}`"
                />
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" dark color="#e62d2d">
            <v-card-text v-if="GET_COUNT.REJECTED">
              <div class="card-title">{{ $t("Rejected card") }}</div>
              <div class="card-count">
                {{ GET_COUNT.REJECTED.TOTAL }}
              </div>
              <ul
                class="card-branch-count"
                v-if="GET_COUNT.REJECTED.BRANCHES.length > 0"
              >
                <li
                  v-for="{ id, name, count } in GET_COUNT.REJECTED.BRANCHES"
                  :key="id"
                  v-text="`${name}: ${count}`"
                />
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" dark color="orange">
            <v-card-text v-if="GET_COUNT.SUBMITTED">
              <div class="card-title">{{ $t("Submitted card") }}</div>
              <div class="card-count">
                {{ GET_COUNT.SUBMITTED.TOTAL }}
              </div>
              <ul
                class="card-branch-count"
                v-if="GET_COUNT.SUBMITTED.BRANCHES.length > 0"
              >
                <li
                  v-for="{ id, name, count } in GET_COUNT.SUBMITTED.BRANCHES"
                  :key="id"
                  v-text="`${name}: ${count}`"
                />
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-card class="position-relative">
            <v-tabs
              v-model="tabBranch"
              class="mb-3 pt-2"
              v-if="branches"
              color="success"
            >
              <v-tab
                v-for="(b, index) of branches"
                class="transform-none text-uppercase font-weight-black mr-1"
                :disabled="isLoading"
                :key="index"
                :value="b.id"
              >
                <v-badge
                  color="success"
                  offset-x="1"
                  class="styled-badge"
                  :content="
                    b.id === -1
                      ? GET_COUNT.ALL.TOTAL
                      : GET_COUNT.ALL.BRANCHES.find(
                          (brnch) => b.id === brnch.id
                        )?.count || 0
                  "
                >
                  <span class="for_branch">
                    {{ b.name }}
                  </span>
                </v-badge>
              </v-tab>
            </v-tabs>
            <v-tabs v-model="tab" color="accent">
              <v-tab
                :disabled="isLoading"
                class="transform-none text-uppercase"
              >
                <v-badge
                  color="accent"
                  class="styled-badge"
                  :content="
                    branch === -1
                      ? GET_COUNT.ALL.TOTAL
                      : GET_COUNT.ALL.BRANCHES.find((b) => b.id === branch)
                          ?.count || 0
                  "
                >
                  <span>{{ $t("All applications") }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                :disabled="isLoading"
                class="transform-none text-uppercase"
              >
                <v-badge
                  color="accent"
                  class="styled-badge"
                  :content="
                    branch === -1
                      ? GET_COUNT.SUBMITTED.TOTAL || 0
                      : GET_COUNT.SUBMITTED.BRANCHES.find(
                          (b) => b.id === branch
                        ).count || 0
                  "
                >
                  <span>{{ $t("New applications") }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                :disabled="isLoading"
                class="transform-none text-uppercase"
              >
                <v-badge
                  color="accent"
                  class="styled-badge"
                  :content="
                    branch === -1
                      ? GET_COUNT.REJECTED.TOTAL
                      : GET_COUNT.REJECTED.BRANCHES.find((b) => b.id === branch)
                          ?.count || 0
                  "
                >
                  <span>{{ $t("Rejected app") }}</span>
                </v-badge>
              </v-tab>
              <v-tab
                :disabled="isLoading"
                class="transform-none text-uppercase"
              >
                <v-badge
                  color="accent"
                  class="styled-badge"
                  :content="
                    branch === -1
                      ? GET_COUNT.ACCEPTED.TOTAL
                      : GET_COUNT.ACCEPTED.BRANCHES.find((b) => b.id === branch)
                          ?.count || 0
                  "
                >
                  <span>{{ $t("Accepted app") }}</span>
                </v-badge>
              </v-tab>
            </v-tabs>
            <admin-applications-list
              @loading="(v) => (isLoading = v)"
              :canExport="user.role !== 'ADMIN_VERIFIER'"
              :status="tab"
              :branch="branch"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.card-title {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.527);

  margin-bottom: 20px;
  padding-bottom: 10px;

  font-weight: 500;
  font-size: 18px;

  text-transform: uppercase;

  text-align: center;
}

.card-count {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.527);
  color: white;
  text-align: center;
}

.card-branch-count {
  font-size: 0.9rem;
  padding: 10px 0 0 10px;
  list-style-type: none;
  font-weight: 500;
  color: #f5f5f5;
  text-transform: uppercase;
}

.card-table {
  width: 100%;
  box-shadow: 0 2px 4px rgba(128, 128, 128, 0.267);
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.right-form {
  padding: 15px 15px 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.right-form div {
  width: 300px;
  height: 60px;
}

.styled-badge {
  font-size: 0.7rem;
  font-weight: 300;
  color: #f5f5f5;

  span {
    font-size: 0.85rem;
    font-weight: 500;
    color: #6b6b6b;

    &.for_branch {
      font-weight: 700;
    }
  }
}
</style>
