<script>
import { mapActions } from "vuex";

export default {
  components: {},

  methods: {
    ...mapActions("admin-applications", ["EXPORT_EXAM"]),

    onExport() {
      this.isExport = true;
      this.EXPORT_EXAM().then(() => {
        this.isExport = false;
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="export">
      <h3 class="title">{{ $t("export_for_exams") }}</h3>
      <v-btn
        :loading="isExport"
        color="accent"
        class="ml-2 transform-none"
        @click="onExport"
      >
        {{ $t("Export to excel") }}
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.search-wrapper {
  display: flex;

  @media only screen and(max-width: 1299px) {
    padding: 10px 20px;
  }

  @media only screen and(min-width: 1400px) {
    width: 400px !important;
  }

  @media only screen and(min-width: 1300px) {
    width: 350px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.export {
  margin-top: 30px;
  margin-left: 30px;
}

.title {
  margin-left: 10px;
  margin-bottom: 5px;
}
</style>
