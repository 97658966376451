<script>
import { mapGetters } from "vuex";

import statuses from "../service/app-statuses.js";

export default {
  data: () => ({
    //
  }),

  computed: {
    ...mapGetters("admin-application", ["GET_HISTORYS"]),
  },

  methods: {
    getStatus(status) {
      const param = `name-${this.$i18n.locale}`;
      const value = statuses.find((x) => x.id == status)?.[param];

      return value || status;
    },
  },
};
</script>

<template>
  <v-timeline v-if="GET_HISTORYS.length">
    <v-timeline-item right v-for="(history, i) in GET_HISTORYS" :key="i">
      <template v-slot:opposite>
        <span class="font-weight-bold" v-text="$dateTime(history.date)" />
      </template>
      <div class="py-4">
        <h2
          class="font-weight-light"
          v-html="
            $t('Status changed to {0} by {1}', {
              0: `<span class='red--text'>${getStatus(history.change)}</span>`,
              1: `<span class='accent--text'>${history.admin}</span>`,
            })
          "
        />
      </div>
    </v-timeline-item>
  </v-timeline>

  <div v-else class="text-center pt-5">
    {{ $t("No data") }}
  </div>
</template>

<style lang="scss" scoped>
//
</style>
