import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  regions: [],
  region: {},

  regions_all: [],
});

export const mutations = {
  SET_REGIONS(state, payload) {
    state.regions = vm.$toArray(payload);
  },

  SET_REGION_BY_ID(state, payload) {
    state.region = payload || {};
  },

  SET_REGIONS_ALL(state, payload) {
    state.regions_all = vm.$toArray(payload);
  },
};

export const actions = {
  async INIT_REGIONS({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/region${query}`);
    if (data) {
      commit("SET_REGIONS", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async REGION_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/region/${id}`);
      if (Array.isArray(data)) {
        commit("SET_REGION_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_REGION(ctx, region) {
    let status = false;
    const url = "admin/region";
    const method = region.id ? "$post" : "$put";

    status = await vm.$axios[method](url, region);

    return status;
  },

  async REGION_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/region/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },

  async INIT_REGIONS_ALL({ commit }) {
    const data = await vm.$axios.$get(`admin/region/all`);

    if (data) {
      commit("SET_REGIONS_ALL", data);
    }
  },
  async DELETE_REGION(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/region/${id}`);

    return status;
  },
};

export const getters = {
  GET_REGIONS: (state) => state.regions,
  GET_REGION_BY_ID: (state) => state.region,

  GET_REGIONS_ALL: (state) => state.regions_all,
};
