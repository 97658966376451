import Vue from "vue";
const vm = Vue.prototype;
import router from "@/router";

import $i18n from "../i18n";

export const state = () => ({
  user: {},
});

export const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
};

export const actions = {
  async SIGN_UP(ctx, { phone }) {
    return await vm.$axios.$post("register", { phone });
  },

  async FORGOT_PASSWORD(ctx, { phone }) {
    return await vm.$axios.$post("forgot-password", { phone });
  },

  async LOGIN({ dispatch }, { username, password }) {
    let status = $i18n.t("Internal error. (code-{0})", { 0: 0 });

    try {
      const res = await vm.$axios.post("login", { username, password });

      vm.$cookies.set("token", res?.data?.token);
      if (await dispatch("INIT_USER")) status = null;
    } catch (error) {
      if (error?.response?.data?.error_description) {
        status = error.response.data.error_description;
      } else {
        status = $i18n.t("Internal error. (code-{0})", {
          0: error.response.status,
        });
      }
    }

    return status;
  },

  async GET_USER() {
    const token = vm.$cookies.get("token");
    if (token) {
      const user = await vm.$axios.$get("common/member");
      return user?.id ? user : null;
    } else return null;
  },

  async INIT_USER({ commit }) {
    let status = false;

    const token = vm.$cookies.get("token");

    if (token) {
      const user = await vm.$axios.$get("common/member");
      if (user?.id) {
        commit("SET_USER", user);
        status = true;

        const onAdmin = () => {
          if (router.currentRoute.path == "/") {
            vm.$nextTick(() => {
              router.replace("/admin");
            });
          }
        };

        switch (user.role) {
          case "ABITURIENT":
            if (router.currentRoute.path == "/") {
              vm.$nextTick(() => {
                router.replace("/home");
              });
            }
            break;

          case "ADMIN_BRANCH_SUPERVISOR":
          case "ADMIN_VERIFIER":
          case "ADMIN_SUPER":
            onAdmin();
            break;
        }
      } else {
        vm.$cookies.remove("token");
        if (router.currentRoute.path != "/") {
          router.replace("/");
        }
      }
    }

    return status;
  },

  LOGOUT({ commit }) {
    vm.$cookies.set("token", null);
    commit("SET_USER", {});

    window.location.replace("/");
  },
};

export const getters = {
  GET_USER: (state) => state.user,
};
