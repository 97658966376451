<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container>
    <div class="text-center">
      {{ $route.path }}
    </div>
  </v-container>
</template>
