<script>
import ModalsTemplate from "../ModalsTemplate.vue";

export default {
  components: {
    ModalsTemplate,
  },

  data: () => ({
    model: true,
  }),

  watch: {
    model() {
      setTimeout(() => {
        this.$emit("close");
        this.model = false;
      }, 100);
    },
  },
  methods: {
    onDelete() {
      this.$emit("action");
      this.model = false;
    },
  },
};
</script>

<template>
  <modals-template :title="$t('Are you sure?')" :width="650" v-model="model">
    <template slot="actions">
      <div class="d-flex w-100">
        <v-spacer />
        <v-btn
          color="outline-light"
          class="btn-accent mr-3"
          @click="model = false"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn color="red" class="text--white" @click="onDelete">
          {{ $t("Delete") }}
        </v-btn>
      </div>
    </template>
  </modals-template>
</template>
