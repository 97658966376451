import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  sciences: [],
  science: {},
});

export const mutations = {
  SET_SCIENCES(state, payload) {
    state.sciences = vm.$toArray(payload);
  },

  SET_SCIENCE_BY_ID(state, payload) {
    state.science = payload || {};
  },
};

export const actions = {
  async INIT_SCIENCES({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/science${query}`);
    if (data) {
      commit("SET_SCIENCES", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async SCIENCE_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/science/${id}`);
      if (Array.isArray(data)) {
        commit("SET_SCIENCE_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_SCIENCE(ctx, science) {
    let status = false;
    const url = "admin/science";
    const method = science.id ? "$post" : "$put";

    status = await vm.$axios[method](url, science);

    return status;
  },

  async SCIENCE_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/science/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },

  async GET_SCIENCES_BY_LANG(ctx, { langId }) {
    const url = `admin/education/${langId}/science-complex/all`;
    const data = await vm.$axios.$get(url);
    return vm.$toArray(data);
  },
  async DELETE_SCIENCE(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/science/${id}`);

    return status;
  },
};

export const getters = {
  GET_SCIENCES: (state) => state.sciences,
  GET_SCIENCE_BY_ID: (state) => state.science,
};
