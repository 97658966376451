<script>
import { mapActions, mapGetters } from "vuex";

import ChooseLocale from "../components/ChooseLocale.vue";

export default {
  name: "Home",

  components: { ChooseLocale },

  data: (vm) => ({
    drawer: vm.$vuetify.breakpoint.mdAndUp,
  }),

  computed: {
    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,

    menuItems: (vm) => vm.$toArray(vm.user.menus),
  },

  methods: {
    ...mapActions("auth", ["LOGOUT"]),

    onExit() {
      this.LOGOUT();
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar clipped-left app fixed>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-spacer />

      <choose-locale />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" clipped width="270" app fixed>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-icon color="accent" large>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-title v-text="user.phone" />
        </v-list-item>

        <v-divider />

        <v-list-item
          :to="item.route"
          v-for="item in menuItems"
          :key="item.name"
        >
          <v-list-item-icon>
            <img width="24" :src="item.icon" alt="" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider />
        <v-list-item @click="onExit">
          <v-list-item-icon>
            <v-icon color="accent">mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t('Exit')" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
//
</style>
