var fileDownload = require("js-file-download");

import Vue from "vue";
import $i18n from "../i18n";

import checkApp from "../service/check-application.js";

const vm = Vue.prototype;

const statuses = ["", "/submitted", "/rejected", "/accepted"];

export const state = () => ({
  applications: [],
  count: [],
  application: checkApp(),
});

export const mutations = {
  SET_APPLICATIONS(state, payload) {
    state.applications = vm.$toArray(payload);
  },

  SET_COUNT(state, payload) {
    state.count = payload || {};
  },

  SET_BY_ID(state, payload) {
    state.application = checkApp(payload);
  },
};

export const actions = {
  async INIT_APPLICATIONS({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const branch = Number(options.branch) || -1;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?branchId=${branch}&page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const status = statuses[options.status] || "";

    const data = await vm.$axios.$get(`admin/application${status}${query}`);

    if (data) {
      commit("SET_APPLICATIONS", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async EXPORT_EXCEL(ctx, options) {
    const status = statuses[options.status] || "/all";
    const branch = options.branch;

    const res = await vm.$axios.$getBlob2(
      `admin/application/export/${branch}${status}`
    );

    if (res?.data) {
      const ss = `${status || ""}`.replace("/", "");
      const file_name = `${ss?.trim() ? ss : "all"}_application`;
      const date = new Date().toISOString().substring(0, 10);
      fileDownload(res.data, `${file_name}_${date}.xlsx`);
    }

    return true;
  },

  async EXPORT_EXAM() {
    const res = await vm.$axios.$getBlob2(`admin/application/export/exported`);

    if (res?.data) {
      const file_name = `exam_applications`;
      const date = new Date().toISOString().substring(0, 10);
      fileDownload(res.data, `${file_name}_${date}.xlsx`);
    }

    return true;
  },

  async INIT_COUNT({ commit }) {
    const data = await vm.$axios.$get(`admin/application/count`);

    const counts = {
      ALL: { TOTAL: 0, BRANCHES: [] },
      SUBMITTED: { TOTAL: 0, BRANCHES: [] },
      ACCEPTED: { TOTAL: 0, BRANCHES: [] },
      REJECTED: { TOTAL: 0, BRANCHES: [] },
    };

    if (data) {
      data.forEach((item) => {
        counts[item.status].TOTAL += item.count;
        counts[item.status].BRANCHES.push({
          id: item.branch.id,
          name: item.branch.name,
          count: item.count,
        });
        counts.ALL.TOTAL += item.count;
      });
      counts.ALL.TOTAL =
        counts.SUBMITTED.TOTAL + counts.ACCEPTED.TOTAL + counts.REJECTED.TOTAL;
      counts.SUBMITTED.BRANCHES.sort((a, b) => a.id - b.id);
      counts.ACCEPTED.BRANCHES.sort((a, b) => a.id - b.id);
      counts.REJECTED.BRANCHES.sort((a, b) => a.id - b.id);
      counts.ALL.BRANCHES = counts.SUBMITTED.BRANCHES.map((item) => ({
        id: item.id,
        name: item.name,
        count: 0,
      }));

      counts.ALL.BRANCHES.forEach((item) => {
        item.count +=
          counts.ACCEPTED.BRANCHES.find((i) => i.id === item.id)?.count || 0;
        item.count +=
          counts.REJECTED.BRANCHES.find((i) => i.id === item.id)?.count || 0;
        item.count +=
          counts.SUBMITTED.BRANCHES.find((i) => i.id === item.id)?.count || 0;
      });
    }

    commit("SET_COUNT", counts);
    return true;
  },

  async ACCEPT_APPLICATION({ dispatch }, { id }) {
    if (id) {
      const res = await vm.$axios.$post(`admin/application/${id}/acceptance`);

      const status = res != null;
      if (status) {
        dispatch(
          "SET_SNACKBAR",
          {
            text: $i18n.t("Success"),
            color: "success",
          },
          { root: true }
        );
      }
      return status;
    } else return null;
  },

  async REJECT_APPLICATION({ dispatch }, { id, reviews }) {
    if (id) {
      const res = await vm.$axios.$post(
        `admin/application/${id}/rejects`,
        reviews
      );

      const status = res != null;
      if (status) {
        dispatch(
          "SET_SNACKBAR",
          {
            text: $i18n.t("Success"),
            color: "success",
          },
          { root: true }
        );
      }
      return status;
    } else return null;
  },

  async DELETE_APPLICATION({ dispatch }, { id }) {
    if (id) {
      const res = await vm.$axios.$delete(`admin/application/${id}`);

      const status = res != null;
      if (status) {
        dispatch(
          "SET_SNACKBAR",
          {
            text: $i18n.t("Success"),
            color: "success",
          },
          { root: true }
        );
      }
      return status;
    } else return null;
  },

  async INIT_BY_ID({ commit }, { id }) {
    if (id) {
      const data = await vm.$axios.$get(`admin/application/${id}`);
      if (data) data.id = Number(id);

      commit("SET_BY_ID", data);
      return data != null;
    }
  },
};

export const getters = {
  GET_APPLICATIONS: (state) => state.applications,
  GET_COUNT: (state) => state.count,
  GET_APPLICATION: (state) => state.application,
};
