<script>
import langList from "../utils/lang-list.js";

export default {
  data: () => ({
    langList,
    curLang: {},
  }),

  created() {
    this.getLang(this.$i18n.locale);
  },

  methods: {
    getLang(lang) {
      const curLang = langList.find((x) => x.value == lang);
      if (curLang) {
        this.curLang = curLang;
      } else {
        this.onSelect(langList[0]);
      }
    },

    onSelect(curLang) {
      this.curLang = { ...curLang };
      localStorage.setItem("locale", curLang.value);
      this.$root.$i18n.locale = curLang.value;
    },
  },
};
</script>

<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-list-item class="pr-0 pl-1" v-bind="attrs" v-on="on">
          <v-list-item-avatar size="24">
            <img :src="curLang.img" alt="" />
          </v-list-item-avatar>
          <v-list-item-title class="accent--text">
            <div style="display: flex; align-items: center">
              {{ $t(curLang.name) }}
              <v-icon class="accent--text">mdi-chevron-down</v-icon>
            </div>
          </v-list-item-title>
        </v-list-item>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(lang, index) in langList"
        :key="index"
        :disabled="lang.value == curLang.value"
        @click="onSelect(lang)"
      >
        <v-list-item-avatar size="24">
          <img :src="lang.img" alt="" />
        </v-list-item-avatar>
        <v-list-item-title>{{ $t(lang.name) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped></style>
