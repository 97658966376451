import Vue from "vue";
import VueRouter from "vue-router";

import roleGuard from "./service/role-guard.js";

import login from "./views/auth/login.vue";
import signUp from "./views/auth/sign-up.vue";
import forgotPassword from "./views/auth/forgot-password.vue";
//import endOfTime from "./views/auth/end-of-time.vue";
import Home from "./views/Home.vue";
import main from "./views/client/main.vue";
import application from "./views/client/application.vue";
import allowance from "./views/client/allowance.vue";
import appeal from "./views/client/appeal.vue";
import personalInfo from "./views/client/personal-info.vue";
import faq from "./views/client/faq.vue";
import help from "./views/client/help.vue";
import statistics from "./views/client/statistics.vue";
import result from "./views/client/result.vue";

import Admin from "./views/Admin.vue";
import adminApplications from "./views/admin/admin-applications.vue";
import adminApplicationShow from "./views/admin/admin-application-show.vue";
import adminStatistics from "./views/admin/admin-statistics.vue";
import adminMessages from "./views/admin/admin-messages.vue";
import adminMembers from "./views/admin/admin-members.vue";
import adminCountries from "./views/admin/admin-countries.vue";
import adminAdmissionSession from "./views/admin/admin-admission-session.vue";
import adminRegions from "./views/admin/admin-regions.vue";
import adminCities from "./views/admin/admin-cities.vue";
import adminSchools from "./views/admin/admin-schools.vue";
import adminFaculties from "./views/admin/admin-faculties.vue";
import adminEducationTypes from "./views/admin/admin-education-types.vue";
import adminEducationLanguage from "./views/admin/admin-education-language.vue";
import adminSciences from "./views/admin/admin-sciences.vue";
import adminCertificates from "./views/admin/admin-certificates.vue";
import adminDisabilities from "./views/admin/admin-disabilities.vue";
import adminsSchoolTypes from "./views/admin/admin-school-types.vue";
import adminExportImport from "./views/admin/admin-export-import.vue";
import adminAllowance from "./views/admin/admin-allowance.vue";
import adminResult from "./views/admin/admin-result.vue";
import adminBranches from "./views/admin/admin-branches";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: login,
  },
  {
    path: "/sign-up",
    component: signUp,
  },
  /*{
      path: "/end-of-time",
      component: endOfTime,
    },*/
  {
    path: "/forgot-password",
    component: forgotPassword,
  },
  {
    path: "/home",
    component: Home,
    beforeEnter: roleGuard,
    children: [
      {
        path: "/",
        component: main,
      },
      {
        path: "/application",
        component: application,
      },
      {
        path: "/allowance",
        component: allowance,
      },
      {
        path: "/appeal",
        component: appeal,
      },
      {
        path: "/personal-info",
        component: personalInfo,
      },
      {
        path: "/faq",
        component: faq,
      },
      {
        path: "/help",
        component: help,
      },
      {
        path: "/statistics",
        component: statistics,
      },
      {
        path: "/result",
        component: result,
      },
    ],
  },
  {
    path: "/admin",
    component: Admin,
    beforeEnter: roleGuard,
    children: [
      {
        path: "/",
        component: adminApplications,
      },
      {
        path: "/admin-application-show/:id",
        component: adminApplicationShow,
      },
      {
        path: "/admin-branches",
        component: adminBranches,
      },
      {
        path: "/admin-statistics",
        component: adminStatistics,
      },
      {
        path: "/admin-messages",
        component: adminMessages,
      },
      {
        path: "/admin-members",
        component: adminMembers,
      },
      {
        path: "/admin-admission-session",
        component: adminAdmissionSession,
      },
      {
        path: "/admin-countries",
        component: adminCountries,
      },
      {
        path: "/admin-regions",
        component: adminRegions,
      },
      {
        path: "/admin-areas",
        component: adminCities,
      },
      {
        path: "/admin-schools",
        component: adminSchools,
      },
      {
        path: "/admin-faculties",
        component: adminFaculties,
      },
      {
        path: "/admin-education-types",
        component: adminEducationTypes,
      },
      {
        path: "/admin-education-language",
        component: adminEducationLanguage,
      },
      {
        path: "/admin-sciences",
        component: adminSciences,
      },
      {
        path: "/admin-certificates",
        component: adminCertificates,
      },
      {
        path: "/admin-disabilities",
        component: adminDisabilities,
      },
      {
        path: "/admin-school-types",
        component: adminsSchoolTypes,
      },
      {
        path: "/admin-export-import",
        component: adminExportImport,
      },
      {
        path: "/admin-allowance/:id",
        component: adminAllowance,
      },
      {
        path: "/admin-result/:id",
        component: adminResult,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
