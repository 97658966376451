<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container>
    <strong class="title_text"
      ><h2>Be Smart! Be Skilled! Be Professional!</h2></strong
    >
    <p style="text-align: justify" class="pt-5">
      &nbsp; &nbsp;{{ $t("text1") }}
    </p>
    <p style="text-align: justify">&nbsp;&nbsp; {{ $t("text2") }}</p>
    <p style="text-align: justify">&nbsp;&nbsp; {{ $t("text3") }}&nbsp;</p>
    <p style="text-align: justify">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ $t("text4") }}
    </p>
  </v-container>
</template>
<style lang="scss" scoped>
.title_text {
  text-align: center;
  margin: 25px;
}
</style>
