import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  //
});

export const mutations = {
  //
};

export const actions = {
  async SEND_MESSAGE(ctx, { text, type }) {
    const params = {
      messageBody: text,
      toType: type,
    };
    return await vm.$axios.$post(`admin/sms`, params);
  },
};

export const getters = {
  //
};
