import { date } from "../filters/date";

export default (item) => {
  const object = item || {};

  const toObj = (obj, v = null) => {
    return obj?.id ? { ...obj } : { id: v };
  };
  const toArray = (array) => (Array.isArray(array) ? array : []);

  object.disability = toObj(object.disability);
  object.country = toObj(object.country);
  object.region = toObj(object.region);
  object.city = toObj(object.city);
  object.citizenship = toObj(object.citizenship);
  object.schoolRegion = toObj(object.schoolRegion);
  object.schoolCity = toObj(object.schoolCity);
  object.educationType = toObj(object.educationType);
  object.educationLanguage = toObj(object.educationLanguage);
  object.branch = toObj(object.branch);
  object.educationSchool = toObj(object.educationSchool);
  object.educationFaculty = toObj(object.educationFaculty);
  object.additionalFaculty = toArray(object.additionalFaculty);

  object.schoolType = toObj(object.schoolType);
  object.certificate = toObj(object.certificate);
  object.scienceComplex = toObj(object.scienceComplex);
  object.foreignLanguageProficiency = toObj(
    object.foreignLanguageProficiency,
    1
  );

  object.reviews = object.reviews || {};
  object.certificateNumber = object.certificateNumber || 0;
  object.score = object.score || 0;
  object.birthDate = date(object.birthDate);

  return object;
};
