<script>
import { mapActions, mapGetters } from "vuex";
import ApplicationAdd from "@/components/ApplicationAdd.vue";
import appStatuses from "@/service/app-statuses.js";

export default {
  components: { ApplicationAdd },

  data: () => ({
    popupShow: false,
    popupStage: 1,
    popupAgree: false,
  }),

  created() {
    this.INIT_APPLICATION();
  },

  computed: {
    ...mapGetters("application", ["GET_APPLICATION"]),
    application: (vm) => vm.GET_APPLICATION,
    isReadonly() {
      const status = this.application.status;
      return status != "DRAFT" && status != "REJECTED";
      // return true;
    },
    isAccepted() {
      const status = this.application.status;
      return status == "ACCEPTED";
    },
    isSubmitted() {
      const status = this.application.status;
      return status == "SUBMITTED";
    },
    isRejected() {
      const status = this.application.status;
      return status == "REJECTED";
    },

    applicationStatus() {
      const status =
        appStatuses.find((x) => x.id == this.application.status) || {};

      return {
        name: status[`name-${this.$i18n.locale}`],
        color: color(status.id),
      };

      function color(statusName) {
        switch (statusName) {
          case "ACCEPTED":
            return "#26d317";
          case "REJECTED":
            return "red";
          case "SUBMITTED":
            return "#d8d813";
          default:
            return null;
        }
      }
    },
  },

  methods: {
    ...mapActions("application", ["INIT_APPLICATION"]),
  },
};
</script>

<template>
  <v-container>
    <div
      class="-download-guide"
      v-if="!isRejected && !isAccepted && !isSubmitted"
      v-html="
        $t(
          `Download the application guide <a href='{0}' target='_blank'> here </a>.`,
          { 0: 'https://www.youtube.com/watch?v=y2LHYUzUC5E' }
        )
      "
    />
    <div v-else class="-status-wrap">
      <div
        class="-status"
        :style="{
          backgroundColor: applicationStatus.color,
          textAlign: 'center',
        }"
      >
        {{ $t("Status") }}: {{ applicationStatus.name }}
      </div>
    </div>

    <application-add :isReadonly="isReadonly" />
  </v-container>
</template>

<style lang="scss" scoped>
.-status-wrap {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 50px;

  .-status {
    max-width: 360px;

    padding: 8px 5px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    cursor: default;
    font-size: 20px;
    color: white;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

//
</style>
