export default (stringdate) => {
  if (!stringdate) return null;

  let date = null;

  try {
    const [day, month, year] = stringdate.split(".");
    date = new Date(`${year}-${month}-${day}`);
  } catch {
    //
  }

  if (!date || isNaN(date.getTime())) return null;

  const [year, month, day] = date.toISOString().substr(0, 10).split("-");

  const padDate = day.padStart(2, "0");
  return `${year}-${month.padStart(2, "0")}-${padDate}T00:00:00.208Z`;
};
