<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container>
    <strong class="title_text"
      ><h2>{{ $t("faq_title") }}</h2></strong
    >
    <div class="-cart">
      <v-expansion-panels multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="header_question">{{
            $t("faq_question1")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="body_answer">
            {{ $t("faq_answer1") }}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="header_question">{{
            $t("faq_question2")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="body_answer">
            {{ $t("faq_answer2") }}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="header_question">{{
            $t("faq_question3")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="body_answer">
            {{ $t("faq_answer3") }}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="header_question">{{
            $t("faq_question4")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="body_answer">
            {{ $t("faq_answer4") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="header_question">{{
            $t("faq_question5")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="body_answer">
            {{ $t("faq_answer5") }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>
<style lang="scss" scoped>
.title_text {
  text-align: left;
  margin: 20px;
}

.header_question {
  text-align: center;
  background: #06a2e0;
  color: white;
  font-size: 18px;
}
.body_answer {
  text-align: left;
  font-size: 16px;
  padding-top: 15px;
}
</style>
