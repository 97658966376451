<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    text: {
      type: [String, Number],
      required: false,
    },

    value: {
      type: [String, Number],
      default: () => null,
    },
  },

  computed: {
    model: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<template>
  <div>
    <v-edit-dialog style="width: 100%">
      <div style="min-height: 24px" :class="text ? '-title' : '-text'">
        {{ title }}
        <v-icon class="ml-2" color="red" v-if="model">mdi-alert-box</v-icon>
      </div>
      <div v-if="text" class="-text" v-text="text" />
      <div v-else>
        <slot />
      </div>
      <template v-slot:input>
        <div class="py-3">
          <div class="pb-2" v-text="title" />
          <v-textarea
            hide-details
            autofocus
            rows="2"
            v-model="model"
            outlined
          />
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<style lang="scss" scoped>
.-title {
  font-size: 14px;
}

.-text {
  font-weight: 500;
}
</style>
