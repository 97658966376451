import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  facultys: [],
});

export const mutations = {
  SET_FACULTYS(state, payload) {
    state.facultys = vm.$toArray(payload);
  },
};

export const actions = {
  async INIT_FACULTYS({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/faculty${query}`);
    if (data) {
      commit("SET_FACULTYS", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async FACULTY_BY_ID(ctx, { id }) {
    return await vm.$axios.$get(`admin/faculty/${id}`);
  },

  async ADD_FACULTY(ctx, faculty) {
    let status = false;
    const url = "admin/faculty";
    const method = faculty.id ? "$post" : "$put";

    status = await vm.$axios[method](url, faculty);

    return status;
  },

  async FACULTY_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/faculty/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },
  async DELETE_FACULTY(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/faculty/${id}`);

    return status;
  },
};

export const getters = {
  GET_FACULTYS: (state) => state.facultys,
};
