<script>
import ChooseLocale from "./ChooseLocale.vue";

export default {
  name: "AuthHeader",

  components: {
    ChooseLocale,
  },

  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container class="pt-10">
    <div style="display: flex; padding-bottom: 20px">
      <div>
        <img src="../assets/img/logo_wide_nav.svg" class="logo" alt="logo" />
      </div>
      <v-spacer />
      <choose-locale />
    </div>

    <slot />

      <!-- Edit from NORM frontend Developer -->
      <v-row class="contacts">
        <v-col align-self="center" cols="12" md="4">
          <div class="connect-item-wrap">
            <a href="mailto:info@kiut.uz" class="connect-item" v-ripple>
              <div class="connect-icon">
                <img src="../assets/icons/email.svg" alt="" />
              </div>
              <div class="connect-body">
                <div class="connect-text" v-text="$t('login.email')" />
                <div class="connect-link" v-text="'info@kiut.uz'" />
              </div>
            </a>
          </div>
        </v-col>
        <v-col align-self="center" cols="12" md="4">
          <div class="connect-item-wrap">
            <a href="tel:+998781294040" class="connect-item" v-ripple>
              <div class="connect-icon">
                <img src="../assets/icons/phone.svg" alt="" />
              </div>
              <div class="connect-body">
                <div class="connect-text" v-text="$t('login.contact')" />
                <div class="connect-link" v-text="'+998 78 129 40 40'" />
              </div>
            </a>
          </div>
        </v-col>
        <v-col align-self="center" cols="12" md="4">
          <div class="connect-item-wrap">
            <div class="connect-item">
              <div class="connect-icon">
                <img src="../assets/icons/building.svg" alt="" />
              </div>
              <div class="connect-body">
                <div class="connect-text" v-text="$t('login.address')" />
                <div
                  class="connect-link"
                  v-text="$t('address_of_university_tashkent')"
                />
                <div
                  class="connect-link"
                  v-text="$t('address_of_university_samarkand')"
                />
                <div
                  class="connect-link"
                  v-text="$t('address_of_university_namangan')"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--  -->

      <div class="d-flex">
        <v-spacer />
        <div class="text-center social">
          <div>{{ $t("login.university") }} © 2024</div>
          <div class="footer">
            <a v-ripple href="https://www.facebook.com/kiut.uz/">
              <img width="12" src="@/assets/icons/facebook.svg" alt="" />
            </a>
            <a v-ripple href="https://t.me/kiut_official">
              <img width="23" src="@/assets/icons/telegram.svg" alt="" />
            </a>
            <a v-ripple href="https://www.instagram.com/kiut.uz_rasmiy/">
              <img width="23" src="@/assets/icons/instagram.svg" alt="" />
            </a>
            <a
              v-ripple
              href="https://www.youtube.com/channel/UC9ewNmt05rUSXoZHBsMBYjA"
            >
              <img width="30" src="@/assets/icons/youtube.svg" alt="" />
            </a>
            <a v-ripple href="https://kiut.uz/">
              <img width="18" src="@/assets/icons/internet.svg" alt="" />
            </a>
          </div>
        </div>
        <v-spacer />
      </div>
  </v-container>
</template>

<style lang="scss" scoped>
.connect-item-wrap {
  display: flex;

  user-select: none;

  @media only screen and(min-width: 1264px) {
    // justify-content: center;
  }

  .connect-item {
    display: flex;
    align-items: center;
    line-height: 120%;

    padding: 8px;

    border-radius: 3px;

    text-decoration: none;

    // .connect-icon{

    // }

    .connect-body {
      padding-left: 16px;

      color: #202020;

      .connect-text {
        padding-bottom: 2px;
      }

      .connect-link {
        font-weight: bold;
      }
    }
  }
}

.social{
  padding-top: 20px;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;

  > a {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-radius: 5px;

    user-select: none;
  }
}
</style>