import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  educationLanguages: [],
  educationLanguagesAll: [],
  educationLanguage: {},
});

export const mutations = {
  SET_EDUCATION_LANGUAGES(state, payload) {
    state.educationLanguages = vm.$toArray(payload);
  },

  SET_EDUCATION_LANGUAGE_ALL(state, payload) {
    state.educationLanguagesAll = vm.$toArray(payload);
  },

  SET_EDUCATION_LANGUAGE_BY_ID(state, payload) {
    state.educationLanguage = payload || {};
  },
};

export const actions = {
  async INIT_EDUCATION_LANGUAGES({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/education-language${query}`);
    if (data) {
      commit("SET_EDUCATION_LANGUAGES", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async EDUCATION_LANGUAGE_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/education-language/${id}`);
      if (Array.isArray(data)) {
        commit("SET_EDUCATION_LANGUAGE_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_EDUCATION_LANGUAGE(ctx, educationLanguage) {
    let status = false;
    const url = "admin/education-language";
    const method = educationLanguage.id ? "$post" : "$put";

    status = await vm.$axios[method](url, educationLanguage);

    return status;
  },

  async EDUCATION_LANGUAGE_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/education-language/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },

  async INIT_EDUCATION_LANGUAGE_ALL({ commit }) {
    const data = await vm.$axios.$get(`admin/education-language/all`);
    if (data) {
      commit("SET_EDUCATION_LANGUAGE_ALL", data);
    }
  },
  async DELETE_EDUCATION_LANGUAGE(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/education-language/${id}`);

    return status;
  },
};

export const getters = {
  GET_EDUCATION_LANGUAGES: (state) => state.educationLanguages,
  GET_EDUCATION_LANGUAGE_BY_ID: (state) => state.educationLanguage,
  GET_EDUCATION_LANGUAGE_ALL: (state) => state.educationLanguagesAll,
};
