export default [
  {
    name: "Uzbek",
    value: "uz",
    img: require("../assets/flags/uz.png"),
  },
  {
    name: "Russian",
    value: "ru",
    img: require("../assets/flags/ru.png"),
  },
  {
    name: "English",
    value: "en",
    img: require("../assets/flags/en.png"),
  },
];
