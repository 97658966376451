import i18n from "../i18n.js";

export default {
  required: (v) => !!v || "",
  text: (v) =>
    !!v?.match(/^[a-zA-Z'` ]+$/) || i18n.t("Use only english letters"),
  phone: (v) => v?.length == 9 || "",
  pinfl: (v) => v?.length == 14 || "",
  phone2: (v) => v?.length == 17 || "",
  date: (v) => {
    const num = Number(`${v || ""}`.substr(9));
    return !!num || num == 0 || "";
  },
  passport: (v) => {
    const num = Number(`${v || ""}`.substr(8));
    return !!num || num == 0 || "";
  },
  email: (v) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || "";
  },
};
