<script>
export default {
  props: {
    title: String,
    toolbarColor: {
      type: String,
      default: () => "transparent",
    },
    width: {
      type: [String, Number],
      default: () => 550,
    },
    contentClass: {
      type: String,
      default: () => "py-5",
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
    hideToolbar: {
      type: Boolean,
      default: () => false,
    },
    loading: Boolean,

    value: Boolean,
  },

  computed: {
    model: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },

    hasActions: (vm) => !!vm.$slots.actions,
  },
};
</script>

<template>
  <v-dialog :persistent="persistent" v-model="model" :width="width">
    <v-card :loading="loading" rounded="lg">
      <v-toolbar
        v-if="!hideToolbar"
        class="-toolbar"
        :color="toolbarColor"
        elevation="0"
      >
        <v-toolbar-title> {{ title }} </v-toolbar-title>

        <v-spacer />

        <v-btn @click="model = false" icon>
          <v-icon color="#676B92">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text :class="contentClass">
        <slot />
      </v-card-text>

      <v-card-actions v-if="hasActions" class="pb-6 px-6">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.-toolbar {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
}
</style>
