import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  citys: [],
  city: {},
});

export const mutations = {
  SET_CITYS(state, payload) {
    state.citys = vm.$toArray(payload);
  },

  SET_CITY_BY_ID(state, payload) {
    state.city = payload || {};
  },
};

export const actions = {
  async INIT_CITYS({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/city${query}`);
    if (data) {
      commit("SET_CITYS", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async CITY_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/city/${id}`);
      if (Array.isArray(data)) {
        commit("SET_CITY_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_CITY(ctx, city) {
    let status = false;
    const url = "admin/city";
    const method = city.id ? "$post" : "$put";

    status = await vm.$axios[method](url, city);

    return status;
  },

  async CITY_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/city/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },
  async DELETE_CITY(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/city/${id}`);

    return status;
  },
};

export const getters = {
  GET_CITYS: (state) => state.citys,
  GET_CITY_BY_ID: (state) => state.city,
};
