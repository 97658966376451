<script>
import { debounce } from "debounce";

export default {
  name: "App",

  data: () => ({
    isLocale: true,
  }),

  computed: {
    snackbar: (vm) => vm.$store.getters.GET_SNACKBAR,
  },

  beforeMount() {
    this.onResize();
  },

  watch: {
    async "$i18n.locale"() {
      this.isLocale = false;

      await this.$store.dispatch("auth/INIT_USER");

      this.isLocale = true;
    },
  },

  methods: {
    onResize: debounce(function () {
      const width = window.innerWidth || 0;
      const height = window.innerHeight || 0;

      this.$store.dispatch("SET_SIZE", { width, height });
    }, 800),
  },
};
</script>

<template>
  <v-app v-resize="onResize">
    <router-view v-if="isLocale" />

    <v-container v-else style="height: 400px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          {{ $t("Locale is changing") }}...
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="accent" indeterminate rounded height="6" />
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      top
      v-model="snackbar.isShow"
      :color="snackbar.color"
      :timeout="8500"
    >
      <div v-html="snackbar.text" />
      <template slot="action">
        <v-btn
          icon
          :title="$t('Close')"
          class="textTansform_none"
          dark
          text
          @click="snackbar.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
@import url("./assets/password/stylesheet.css");

.justify-content-center {
  justify-content: center;
}

.pdf-table {
  border-collapse: collapse;
  border-color: black;
}

.pdf-ul {
  margin-bottom: 1rem;
}

* {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c2c2c2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b6b6b6;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
  .container--fluid {
    max-width: 100% !important;
  }
}

.v-data-table-header th {
  white-space: nowrap;
}

.transform-none {
  text-transform: none !important;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.-download-guide {
  margin-top: 30px;
  margin-bottom: 70px;
  color: #0096db;
  text-align: center;

  a {
    margin-right: 5px;
    font-weight: 500;
  }
}

.add-faculty-select .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
}

img {
  max-width: 100%;
  max-height: 100%;
}

img.logo {
  width: 500px;
}

.h-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.v-card__title {
  word-break: break-word !important;
}

#map.map_uzb text {
  display: none;
}
</style>
