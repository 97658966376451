<script>
import PhoneNumber from "awesome-phonenumber";

import allCountries from "./all-countries";

export default {
  props: {
    value: {
      type: String,
      default: () => false,
    },

    disabled: {
      type: Boolean,
      default: () => false,
    },

    required: {
      type: Boolean,
      default: () => false,
    },

    autofocus: {
      type: Boolean,
      default: () => false,
    },

    clearable: {
      type: Boolean,
      default: () => false,
    },

    error: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    countries: [...allCountries],
    activeCountry: allCountries[0],
    phone: null,
  }),

  computed: {
    formatedPhone: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },

    getMask() {
      const code = this.activeCountry.dialCode || "";
      const iso = this.activeCountry.iso2 || "";
      const example = PhoneNumber.getExample(iso).getNumber("international");
      const mask = example
        ?.replace("+", "")
        ?.replace(code, "")
        ?.replace(/\d/g, "#")
        ?.trim();

      return mask || "";
    },

    maskRule: (vm) => (v) => v?.trim()?.length == vm.getMask.length || "",
  },

  created() {
    const code = this.activeCountry.dialCode;
    if (`${this.formatedPhone || ""}`.trim() && code) {
      this.phone = `${this.formatedPhone}`?.replace("+", "")?.replace(code, "");
    } else {
      this.formatedPhone = null;
    }
  },

  watch: {
    phone(v) {
      const isValid = this.maskRule(v);
      const code = this.activeCountry?.dialCode;

      if (!v) {
        this.$emit("clear");
      }

      if (isValid == true) {
        this.formatedPhone = `${code}${v.replace(/\s/g, "")}`;
      } else if (this.formatedPhone) {
        this.formatedPhone = null;
      }
    },
  },

  methods: {
    onChangeCountryCode() {
      this.phone = null;
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <div style="flex: 1">
      <v-select
        v-model="activeCountry"
        @change="onChangeCountryCode"
        :items="countries"
        outlined
        :disabled="disabled"
        item-text="name"
        item-value="iso2"
        return-object
      >
        <template v-slot:selection>
          <div class="d-flex align-center">
            <span :class="activeCountry.iso2.toLowerCase()" class="vti__flag" />
            <span> {{ `+${activeCountry.dialCode}` }}</span>
          </div>
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </div>
    <div style="flex: 3">
      <v-text-field
        :error="error"
        outlined
        :disabled="disabled"
        v-mask="getMask"
        v-model="phone"
        :clearable="clearable"
        :rules="[required ? $rules.required : true, maskRule]"
        @keyup.enter="$emit('enter')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("./sprite.css");

.vti__flag {
  margin-right: 8px;
}
</style>
