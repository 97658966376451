import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: getModules(),

  state: {
    snackbar: {
      text: false,
      color: "success",
      isShow: false,
    },

    size: {
      width: 992,
      height: 992,
    },
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      snackbar.isShow = true;
      state.snackbar = snackbar;
    },

    SET_SIZE(state, { width = 0, height = 0 }) {
      state.size = { width, height };
    },
  },

  actions: {
    SET_SNACKBAR({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },
    SET_SIZE({ commit }, { width, height }) {
      commit("SET_SIZE", { width, height });
    },

    async INIT_STATES({ dispatch }) {
      await dispatch("auth/INIT_USER").then(async (status) => {
        if (status) {
          // await dispatch("region/INIT_REGIONS");
        }
      });
    },
  },

  getters: {
    GET_SIZE: (state) => state.size,

    GET_SNACKBAR: (state) => state.snackbar,
  },
});

function getModules() {
  const modulesCtx = require.context("./", true, /[A-Za-z0-9-_,\s]+\.js$/i);

  const modules = {};

  modulesCtx.keys().forEach((key) => {
    if (key !== "./index.js") {
      const name = key.replace("./", "").replace(".js", "");
      let module = modulesCtx(key);
      module.namespaced = true;
      modules[name] = module;
    }
  });
  return modules;
}
