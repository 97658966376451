var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isAddModal)?_c('school-type-add',{attrs:{"data":_vm.selected},on:{"close":function($event){_vm.isAddModal = false},"refresh":_vm.getItems}}):_vm._e(),_c('v-card',{staticClass:"pa-5"},[_c('div',{staticClass:"d-flex pb-5"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('School types'))}}),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.onAddModel}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"loading":_vm.isLoading},on:{"click":_vm.getItems}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('div',{staticClass:"pl-5",staticStyle:{"width":"300px"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('Search...'),"append-icon":"mdi-magnify","dense":"","hide-details":"","outlined":"","clearable":""},on:{"click:append":_vm.getItems,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getItems.apply(null, arguments)},"click:clear":_vm.getItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"mobile-breakpoint":0,"height":_vm.tableHeight,"loading":_vm.isLoading,"headers":[
        {
          text: '№',
          value: 'row-number',
          width: 20,
          sortable: false,
        },
        {
          text: _vm.$t('Name uz'),
          value: 'name->uz',
        },
        {
          text: _vm.$t('Name ru'),
          value: 'name->ru',
        },
        {
          text: _vm.$t('Name en'),
          value: 'name->en',
        },
        {
          text: '',
          value: 'edit',
          width: 20,
          sortable: false,
        },
        {
          text: '',
          value: 'block',
          width: 20,
          sortable: false,
        },
      ],"fixed-header":"","items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"item-key":"id","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.rowsPerPage }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('tbody',[_vm._l((props.items),function(item,index){return _c('tr',{key:item.id},[_c('td',{domProps:{"textContent":_vm._s(index + 1)}}),_c('td',{domProps:{"textContent":_vm._s(item.names.uz)}}),_c('td',{domProps:{"textContent":_vm._s(item.names.ru)}}),_c('td',{domProps:{"textContent":_vm._s(item.names.en)}}),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"cursor-pointer px-2",on:{"click":function($event){return _vm.onEdit({ item })}}},[_c('v-icon',{attrs:{"title":_vm.$t('Edit')}},[_vm._v(" mdi-pencil ")])],1),_c('td',{staticClass:"pr-0",on:{"click":function($event){return _vm.onSwitch({ item })}}},[_c('v-switch',{staticClass:"mt-0",attrs:{"loading":_vm.isLoading,"hide-details":"","inset":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)])}),(!props.items.length && !_vm.isLoading)?_c('tr',[_c('td',{staticClass:"text-center py-4",attrs:{"colspan":props.headers.length + 1}},[_vm._v(" "+_vm._s(_vm.$t("No data"))+" ")])]):_vm._e()],2)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }