import imageCompression from "browser-image-compression";

export default async (imageFile) => {
  if (imageFile instanceof Blob) {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      return await imageCompression(imageFile, options);
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};
