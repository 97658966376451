import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  disabilities: [],
  disability: {},
});

export const mutations = {
  SET_DISABILITIES(state, payload) {
    state.disabilities = vm.$toArray(payload);
  },

  SET_DISABILITY_BY_ID(state, payload) {
    state.disability = payload || {};
  },
};

export const actions = {
  async INIT_DISABILITIES({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/disability${query}`);
    if (data) {
      commit("SET_DISABILITIES", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async DISABILITY_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/disability/${id}`);
      if (Array.isArray(data)) {
        commit("SET_DISABILITY_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_DISABILITY(ctx, disability) {
    let status = false;
    const url = "admin/disability";
    const method = disability.id ? "$post" : "$put";

    status = await vm.$axios[method](url, disability);

    return status;
  },

  async DISABILITY_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/disability/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },
};

export const getters = {
  GET_DISABILITIES: (state) => state.disabilities,
  GET_DISABILITY_BY_ID: (state) => state.disability,
};
