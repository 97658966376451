<script>
import { mapActions } from "vuex";

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    image: null,
  }),

  created() {
    this.GET_FILE_API({ url: this.url }).then((image) => {
      this.image = image;
    });
  },

  methods: {
    ...mapActions("application", ["GET_FILE_API"]),
  },
};
</script>

<template>
  <div v-if="image">
    <img class="cursor-pointer" v-viewer :src="image" alt="" />
  </div>
  <v-skeleton-loader v-else class="h-100 w-100" type="image" />
</template>

<style lang="scss" scoped>
img {
  height: 250px;
}
</style>
