export default [
  {
    "name-uz": "Qabul qilingan",
    "name-ru": "Принятый",
    "name-en": "Accepted",
    id: "ACCEPTED",
  },
  {
    "name-uz": "Qaytarilgan",
    "name-ru": "Возвращенный",
    "name-en": "rejected",
    id: "REJECTED",
  },
  {
    "name-uz": "Kutilyapti",
    "name-ru": "Ожидаемый",
    "name-en": "Waiting",
    id: "SUBMITTED",
  },
];
