import Vue from "vue";

const vm = Vue.prototype;

export const state = () => ({
  genders: [],
  dailys: [],
  facultys: [],
  regions: [],
});

export const mutations = {
  SET_GENDERS(state, payload) {
    state.genders = vm.$toArray(payload);
  },

  SET_DAILYS(state, payload) {
    state.dailys = vm.$toArray(payload);
  },

  SET_FACULTYS(state, payload) {
    state.facultys = vm.$toArray(payload);
  },

  SET_REGIONS(state, payload) {
    state.regions = vm.$toArray(payload);
  },
};

export const actions = {
  async INIT_GENDERS({ commit }, branchId) {
    let status = false;
    const data = await vm.$axios.$get(`admin/statistics/gender/${branchId}`);

    status = data != null;
    commit("SET_GENDERS", data);

    return status;
  },

  async INIT_DAILYS({ commit }, branchId) {
    let status = false;
    const data = await vm.$axios.$get(`admin/statistics/daily/${branchId}`);

    status = data != null;
    commit("SET_DAILYS", data);

    return status;
  },

  async INIT_FACULTYS({ commit }, branchId) {
    let status = false;
    const data = await vm.$axios.$get(`admin/statistics/faculty/${branchId}`);

    status = data != null;
    commit("SET_FACULTYS", data);

    return status;
  },

  async INIT_REGIONS({ commit }, branchId) {
    let status = false;
    const data = await vm.$axios.$get(`admin/statistics/regional/${branchId}`);

    status = data != null;
    commit("SET_REGIONS", data);

    return status;
  },
};

export const getters = {
  GET_GENDERS: (state) => state.genders,
  GET_DAILYS: (state) => state.dailys,
  GET_FACULTYS: (state) => state.facultys,
  GET_REGIONS: (state) => state.regions,
};
