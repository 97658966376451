<script>
import { mapActions, mapGetters } from "vuex";
import genders from "../service/genders";

import AppAdditionalEduDirs from "../components/AppAdditionalEduDirsAdmin.vue";
import UploadFileAdmin from "./UploadFileAdmin.vue";

export default {
  components: { UploadFileAdmin, AppAdditionalEduDirs },

  props: {
    isReadonly: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    isLoading: false,

    isValid: false,

    genders,

    years: Array.from(
      { length: new Date().getFullYear() - 1950 },
      (value, index) => 1951 + index
    ).reverse(),
  }),

  computed: {
    ...mapGetters("admin-application", [
      "GET_APPLICATION",
      "GET_DISABILITYS",
      "GET_COUNTRYS",
      "GET_REGIONS",
      "GET_CITYS",
      "GET_SCHOOL_REGIONS",
      "GET_SCHOOL_CITYS",
      "GET_BRANCHES",
      "GET_EDUCATION_SCHOOLS",
      "GET_EDUCATION_FACULTYS",
      "GET_EDUCATION_TYPES",
      "GET_EDUCATION_LANGUAGES",
      "GET_SCHOOL_TYPES",
      "GET_CERTIFICATES",
      "GET_ADDITIONAL_FACULTIES",
    ]),
    application: (vm) => vm.GET_APPLICATION,

    isCert: (vm) => vm.application.foreignLanguageProficiency.id !== 1,
    isOtherCert: (vm) => vm.application.foreignLanguageProficiency.id === 6,
  },

  methods: {
    ...mapActions("admin-application", [
      "INIT_APPLICATION",
      "SET_APPLICATION",
      "SAVE_APPLICATION",
      "INIT_REGIONS",
      "INIT_CITYS",
      "INIT_SCHOOL_CITYS",
      "INIT_BRANCHES",
      "INIT_EDUCATION_FACULTYS",
      "INIT_EDUCATION_TYPES",
      "INIT_EDUCATION_LANGUAGES",
      "INIT_EDUCATION_SCHOOLS",
      "INIT_ADDITIONAL_FACULTIES",
    ]),

    onChangeApp() {
      this.$nextTick(() => {
        this.SET_APPLICATION(this.application);
      });
    },

    onChangeForeignLanguageProficiency() {
      if (this.application.foreignLanguageProficiency.id === 1) {
        this.application.score = 0;
        this.application.certificateNumber = 0;
      }

      this.$nextTick(() => {
        this.SET_APPLICATION(this.application);
      });
    },

    onChangeCountry() {
      const params = { countryId: this.application.country.id };
      this.isLoading = true;

      this.INIT_REGIONS(params).then(() => {
        this.isLoading = false;
      });

      this.application.region.id = null;
      this.application.city.id = null;

      this.onChangeApp();
    },

    onChangeRegion() {
      const params = { regionId: this.application.region.id };
      this.isLoading = true;

      this.INIT_CITYS(params).then(() => {
        this.isLoading = false;
      });

      this.application.city.id = null;

      this.onChangeApp();
    },

    onChangeSchoolRegion() {
      const params = { regionId: this.application.schoolRegion.id };
      this.isLoading = true;

      this.INIT_SCHOOL_CITYS(params).then(() => {
        this.isLoading = false;
      });

      this.application.schoolCity.id = null;

      this.onChangeApp();
    },

    onChangeBranch() {
      const params = { branchId: this.application.branch.id };
      this.isLoading = true;

      this.application.educationSchool.id = null;
      this.application.educationFaculty.id = null;
      this.application.educationType.id = null;
      this.application.educationLanguage.id = null;
      this.application.additionalFaculty = [];

      this.INIT_EDUCATION_SCHOOLS(params).then(() => {
        this.isLoading = false;
      });

      this.onChangeApp();
    },

    onChangeEducationSchool() {
      const params = { schoolId: this.application.educationSchool.id };
      this.isLoading = true;

      this.application.educationFaculty.id = null;
      this.application.educationType.id = null;
      this.application.educationLanguage.id = null;
      this.application.additionalFaculty = [];

      this.INIT_EDUCATION_FACULTYS(params).then(() => {
        this.isLoading = false;
      });

      this.onChangeApp();
    },

    onChangeEducationFaculty() {
      const params = { id: this.application.educationFaculty.id };
      this.isLoading = true;

      this.application.educationType.id = null;
      this.application.educationLanguage.id = null;
      this.application.additionalFaculty = [];

      this.INIT_EDUCATION_TYPES(params).then(() => {
        this.isLoading = false;
      });
      this.application.educationType.id = null;

      this.onChangeApp();
    },

    onChangeEducationType() {
      const params = {
        typeId: this.application.educationType.id,
        facultyId: this.application.educationFaculty.id,
      };

      this.isLoading = true;

      this.application.educationLanguage.id = null;
      this.application.additionalFaculty = [];

      this.INIT_EDUCATION_LANGUAGES(params).then(() => {
        this.isLoading = false;
      });

      this.application.educationLanguage.id = null;

      this.onChangeApp();
    },

    onChangeEducationLanguage() {
      const id = this.application.educationLanguage.id;
      const langs = this.GET_EDUCATION_LANGUAGES;
      const lang = langs.find((x) => x.language.id === id);

      this.application.additionalFaculty = [];

      if (lang) {
        this.application.scienceComplex = lang.scienceComplex || {};

        const params = {
          scienceComplexId: this.application.scienceComplex.id,
          facultyId: this.application.educationFaculty.id,
          typeId1: this.application.educationType.id,
        };

        this.isLoading = true;

        this.INIT_ADDITIONAL_FACULTIES(params).then(() => {
          this.isLoading = false;
        });

        this.onChangeApp();
      }
    },

    onSend() {
      this.isValid = true;
      this.$refs.form.validate();
      this.$nextTick(() => {
        const el = document.querySelector(".error--text");

        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          this.SAVE_APPLICATION(this.application).then((status) => {
            if (status) {
              window.location.reload();
            }
          });
        }
      });
    },
  },
};
</script>

<template>
  <v-form
    :readonly="isReadonly"
    @submit.prevent="() => false"
    ref="form"
    lazy-validation
  >
    <div class="section-header">1. {{ $t("Personal information") }}</div>
    <div class="py-13 px-6">
      <v-row>
        <v-col cols="12" md="4">
          <div>
            <div class="form-label">
              {{ $t("Name (as in Passport)") }} <span v-text="'*'" />
            </div>

            <v-text-field
              autofocus
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.firstName"
              dense
              outlined
              required
              :rules="[$rules.text]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.firstName && !isReadonly"
                v-text="application.reviews.firstName"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Last name (as in passport)") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.lastName"
              dense
              outlined
              required
              :rules="[$rules.text]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.lastName && !isReadonly"
                v-text="application.reviews.lastName"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Father's name (as in the passport)") }}
              <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.middleName"
              dense
              outlined
              required
              :rules="[$rules.text]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.middleName && !isReadonly"
                v-text="application.reviews.middleName"
                class="red--text review-msg"
              />
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div>
            <div class="form-label">
              {{ $t("Date of birth") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.birthDate"
              dense
              outlined
              v-inputmask="$masks.date"
              required
              :rules="[$rules.required, $rules.date]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.birthDate && !isReadonly"
                v-text="application.reviews.birthDate"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Gender") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.gender"
              :items="genders"
              :item-text="`name-${$i18n.locale}`"
              item-value="id"
              dense
              outlined
              required
              :rules="[$rules.required]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.gender && !isReadonly"
                v-text="application.reviews.gender"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Disability status") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.disability.id"
              dense
              outlined
              required
              :items="GET_DISABILITYS"
              :rules="[$rules.required]"
              item-text="name"
              item-value="id"
            />
          </div>

          <div
            v-if="application.disability.id && application.disability.id !== 5"
          >
            <div class="form-label">
              {{ $t("Disability note") }}
            </div>
            <v-textarea
              rows="2"
              autocomplete="off"
              v-model="application.disabilityNote"
              @blur="onChangeApp"
              outlined
              hide-details
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.disabilityNote && !isReadonly"
                v-text="application.reviews.disabilityNote"
                class="red--text review-msg"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="form-label">
            {{ $t("Upload your picture") }} <span v-text="'*'" />
          </div>

          <div
            v-if="application.reviews.photo && !isReadonly"
            v-text="application.reviews.photo"
            class="red--text pb-2"
          />

          <UploadFileAdmin param="photo" class="mt-5" />
        </v-col>
      </v-row>
    </div>

    <div class="section-header">2. {{ $t("Location information") }}</div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Country") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeCountry"
              v-model="application.country.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_COUNTRYS"
              item-text="name"
              item-value="id"
              :loading="isLoading"
            />
          </div>

          <div>
            <div class="form-label">
              {{ $t("Region") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeRegion"
              v-model="application.region.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_REGIONS"
              item-text="name"
              item-value="id"
            />
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("City / District") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.city.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_CITYS"
              item-text="name"
              item-value="id"
              :loading="isLoading"
            />
          </div>

          <div>
            <div class="form-label">
              {{ $t("Address") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.addressLine"
              dense
              outlined
              :placeholder="$t(`Enter the address ...`)"
              required
              :rules="[$rules.required]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.addressLine && !isReadonly"
                v-text="application.reviews.addressLine"
                class="red--text review-msg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-header">3. {{ $t("Citizen information") }}</div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Citizenship") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.citizenship.id"
              dense
              outlined
              required
              :items="GET_COUNTRYS"
              item-text="name"
              item-value="id"
              :rules="[$rules.required]"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Nationality") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.nationality"
              dense
              outlined
              required
              :rules="[$rules.required]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.nationality && !isReadonly"
                v-text="application.reviews.nationality"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Passport series and number") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.passportNumber"
              dense
              outlined
              placeholder="AA 1234567"
              required
              :rules="[$rules.required, $rules.passport]"
              v-inputmask="$masks.pasport"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.passportNumber && !isReadonly"
                v-text="application.reviews.passportNumber"
                class="red--text review-msg"
              />
            </div>
          </div>
          <div>
            <div class="form-label">
              {{ $t("Upload a copy of your passport") }} <span v-text="'*'" />
            </div>

            <div
              v-if="application.reviews.passportPhoto && !isReadonly"
              v-text="application.reviews.passportPhoto"
              class="red--text pb-2"
            />

            <UploadFileAdmin param="passportPhoto" />
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("PINFL") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.pinfl"
              dense
              outlined
              required
              :rules="[$rules.pinfl, (v) => v.match(/[0-9]/) || '']"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.pinfl && !isReadonly"
                v-text="application.reviews.pinfl"
                class="red--text review-msg"
              />
            </div>
          </div>
          <div><img src="../assets/pinfl.jpg" alt="" /></div>
        </div>
      </div>
    </div>
    <div class="section-header">4. {{ $t("Contact information") }}</div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Personal phone number") }} <span v-text="'*'" />
            </div>
            <!-- <v-text-field
              autocomplete="off"
              v-inputmask="$masks.number2"
              @blur="onChangeApp"
              v-model="application.mobilePhone"
              dense
              outlined
              required
              :rules="[$rules.required]"
            /> -->

            <tel-input dense v-model="application.mobilePhone" required />

            <div class="position-relative">
              <div
                v-if="application.reviews.mobilePhone && !isReadonly"
                v-text="application.reviews.mobilePhone"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Home phone number") }}
            </div>

            <!-- <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.homePhone"
              v-inputmask="$masks.number2"
              dense
              outlined
            /> -->

            <tel-input dense v-model="application.homePhone" />

            <div class="position-relative">
              <div
                v-if="application.reviews.homePhone && !isReadonly"
                v-text="application.reviews.homePhone"
                class="red--text review-msg"
              />
            </div>
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Father's phone number") }} <span v-text="'*'" />
            </div>

            <!-- <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.fatherPhone"
              v-inputmask="$masks.number2"
              dense
              outlined
            /> -->

            <tel-input dense v-model="application.fatherPhone" required />

            <div class="position-relative">
              <div
                v-if="application.reviews.fatherPhone && !isReadonly"
                v-text="application.reviews.fatherPhone"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Mother's phone number") }} <span v-text="'*'" />
            </div>

            <!-- <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.motherPhone"
              v-inputmask="$masks.number2"
              dense
              outlined
            /> -->

            <tel-input dense v-model="application.motherPhone" required />

            <div class="position-relative">
              <div
                v-if="application.reviews.motherPhone && !isReadonly"
                v-text="application.reviews.motherPhone"
                class="red--text review-msg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-header">
      5. {{ $t("Secondary education information") }}
    </div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Graduated school / college / lyceum") }}
              <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.schoolType.id"
              dense
              outlined
              required
              :items="GET_SCHOOL_TYPES"
              :rules="[$rules.required]"
              item-text="name"
              item-value="id"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Schools name") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.schoolName"
              dense
              outlined
              required
              :rules="[$rules.required]"
              style="text-align: left"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.schoolName && !isReadonly"
                v-text="application.reviews.schoolName"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Region") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeSchoolRegion"
              v-model="application.schoolRegion.id"
              dense
              outlined
              required
              :items="GET_SCHOOL_REGIONS"
              item-text="name"
              item-value="id"
              :rules="[$rules.required]"
            />
          </div>

          <div>
            <div class="form-label">
              {{ $t("City / District") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeApp"
              v-model="application.schoolCity.id"
              dense
              outlined
              required
              :items="GET_SCHOOL_CITYS"
              item-text="name"
              item-value="id"
              :rules="[$rules.required]"
            />
          </div>

          <div>
            <div class="form-label">
              {{ $t("Year of graduation") }} <span v-text="'*'" />
            </div>
            <!-- <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.graduationYear"
              dense
              outlined
              required
              :rules="[$rules.required]"
              v-inputmask="{ mask: '9999' }"
              style="text-align: left"
            /> -->

            <v-select
              @change="onChangeApp"
              :items="years"
              v-model="application.graduationYear"
              dense
              outlined
              required
              :rules="[$rules.required]"
              v-inputmask="{ mask: '9999' }"
              style="text-align: left"
            />

            <div class="position-relative">
              <div
                v-if="application.reviews.graduationYear && !isReadonly"
                v-text="application.reviews.graduationYear"
                class="red--text review-msg"
              />
            </div>
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Diploma / Certificate number") }} <span v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.highSchoolDiplomaNumber"
              dense
              outlined
              required
              :rules="[$rules.required]"
            />
          </div>

          <div>
            <div class="form-label">
              {{ $t("Download a copy of the Certificate / Diploma") }}
              <span v-text="'*'" />
            </div>

            <div
              v-if="application.reviews.highSchoolDiplomaPhoto && !isReadonly"
              v-text="application.reviews.highSchoolDiplomaPhoto"
              class="red--text pb-2"
            />

            <UploadFileAdmin param="highSchoolDiplomaPhoto" />
          </div>
        </div>
      </div>
    </div>
    <div class="section-header">6. {{ $t("Learn foreign languages") }}</div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Certificate type") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeForeignLanguageProficiency"
              v-model="application.foreignLanguageProficiency.id"
              dense
              outlined
              required
              :items="GET_CERTIFICATES"
              :rules="[$rules.required]"
              item-text="name"
              item-value="id"
            />
            <div class="position-relative">
              <div
                v-if="
                  application.reviews.foreignLanguageProficiency && !isReadonly
                "
                v-text="application.reviews.foreignLanguageProficiency"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div
            v-if="
              application.foreignLanguageProficiency.id &&
              application.foreignLanguageProficiency.id === 6
            "
          >
            <div class="form-label">
              {{ $t("enter_name_certificate") }}
              <span v-if="isOtherCert" v-text="'*'" />
            </div>
            <v-text-field
              rows="1"
              autocomplete="off"
              v-model="application.certificateOther"
              @blur="onChangeApp"
              dense
              outlined
              :required="isOtherCert"
              :disabled="!isOtherCert"
              :rules="[isOtherCert ? $rules.required : true]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.certificateOther && !isReadonly"
                v-text="application.reviews.certificateOther"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Score") }}
              <span v-if="isCert" v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.score"
              dense
              outlined
              :required="isCert"
              :rules="[isCert ? $rules.required : true]"
              v-inputmask="$masks.number"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.score && !isReadonly"
                v-text="application.reviews.score"
                class="red--text review-msg"
              />
            </div>
          </div>

          <div>
            <div class="form-label">
              {{ $t("Certificate number") }}
              <span v-if="isCert" v-text="'*'" />
            </div>
            <v-text-field
              autocomplete="off"
              @blur="onChangeApp"
              v-model="application.certificateNumber"
              dense
              outlined
              :required="isCert"
              :rules="[isCert ? $rules.required : true]"
            />
            <div class="position-relative">
              <div
                v-if="application.reviews.certificateNumber && !isReadonly"
                v-text="application.reviews.certificateNumber"
                class="red--text review-msg"
              />
            </div>
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <div class="form-label">
            {{ $t("Upload a copy of the certificate") }}
            <span v-if="isCert" v-text="'*'" />
          </div>

          <div
            v-if="
              application.reviews.foreignLanguageProficiencyPhoto && !isReadonly
            "
            v-text="application.reviews.foreignLanguageProficiencyPhoto"
            class="red--text pb-2"
          />

          <UploadFileAdmin param="foreignLanguageProficiencyPhoto" />
        </div>
      </div>
    </div>
    <div class="section-header">7. {{ $t("Choice of study") }}</div>
    <div class="py-13 px-6">
      <div class="row">
        <div class="cols-12 col-md-5">
          <div>
            <div class="form-label">
              {{ $t("Choose branch") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeBranch"
              v-model="application.branch.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_BRANCHES"
              item-value="id"
              item-text="name"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Choose school") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeEducationSchool"
              v-model="application.educationSchool.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_EDUCATION_SCHOOLS"
              item-value="id"
              item-text="name"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Choose a field of study") }} <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              @change="onChangeEducationFaculty"
              :items="GET_EDUCATION_FACULTYS"
              v-model="application.educationFaculty.id"
              item-value="id"
              item-text="name"
              dense
              outlined
              required
              :rules="[$rules.required]"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Choose the type of education") }} <span v-text="'*'" />
            </div>
            <v-select
              item-value="id"
              item-text="name"
              autocomplete="off"
              @change="onChangeEducationType"
              v-model="application.educationType.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
              :items="GET_EDUCATION_TYPES"
            />
          </div>
          <div>
            <div class="form-label">
              {{ $t("Choose the language of instruction") }}
              <span v-text="'*'" />
            </div>
            <v-select
              autocomplete="off"
              item-value="language.id"
              item-text="language.name"
              @change="onChangeEducationLanguage"
              :items="GET_EDUCATION_LANGUAGES"
              v-model="application.educationLanguage.id"
              dense
              outlined
              required
              :rules="[$rules.required]"
            />
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.mdAndUp" class="col-2" />

        <div class="cols-12 col-md-5">
          <template v-if="GET_ADDITIONAL_FACULTIES.length">
            <div class="form-label">
              {{ $t("Additional study areas") }}
            </div>

            <app-additional-edu-dirs />
          </template>
        </div>
      </div>
      <div v-if="!!application.scienceComplex.id" class="row">
        <div class="col-12">
          <h4 class="d-inline-block">{{ $t("Science Complex") }}:</h4>
          {{ application.scienceComplex.name }}
        </div>
      </div>
    </div>
    <div class="row">
      <div align="center" class="col-12">
        <v-btn :disabled="isReadonly" @click="onSend" color="accent">
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.section-header {
  background: rgb(0, 150, 219);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  color: white;
  padding: 5px;
}

.form-label {
  font-weight: 500;
  margin-bottom: 8px;

  span {
    padding-left: 2px;
    color: red;
  }
}

.review-msg {
  position: absolute;
  top: -26px;
}
</style>
