<script>
import { mapGetters, mapActions } from "vuex";
import appConfirm from "./modals/app-confirm.vue";

import compressImage from "../service/compress-image.js";

export default {
  components: { appConfirm },
  props: {
    param: {
      type: String,
      required: true,
    },

    isValid: {
      type: Boolean,
      required: true,
    },

    required: {
      type: Boolean,
      default: () => false,
    },

    readonly: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    uniqId: Date.now().toString(36) + Math.random().toString(36).substr(2),
    image: null,
    loading: false,
    deleteDialog: false,
  }),

  computed: {
    ...mapGetters("application", ["GET_APPLICATION"]),
    application: (vm) => vm.GET_APPLICATION,
    imagePath: (vm) => vm.application[vm.param],

    isAccepted() {
      const status = this.application.status;
      return status == "ACCEPTED";
    },

    isSubmitted() {
      const status = this.application.status;
      return status == "SUBMITTED";
    },
  },

  watch: {
    imagePath() {
      this.initImage();
    },
  },

  created() {
    this.initImage();
  },

  methods: {
    ...mapActions("application", [
      "UPLOAD_FILE",
      "GET_FILE_API",
      "DELETE_FILE_API",
    ]),

    async onChange(event) {
      const files = event?.target?.files || [];
      if (files?.length) {
        const file = await compressImage(files[0]);
        if (file) {
          this.image = null;
          this.loading = true;
          await this.UPLOAD_FILE({ file, param: this.param }).then(() => {
            this.initImage();
          });
        }
      }
    },

    initImage() {
      if (this.imagePath) {
        this.loading = true;
        this.GET_FILE_API({ url: this.$file(this.imagePath) }).then((res) => {
          this.image = res;
          this.loading = false;
        });
      }
    },

    onDelete() {
      this.DELETE_FILE_API({ param: this.param }).then((res) => {
        if (res != null) {
          this.image = null;
        }
      });
    },
  },
};
</script>

<template>
  <div class="-wrap" style="position: relative">
    <app-confirm
      v-model="deleteDialog"
      :text="$t('Do you really want to delete?')"
      @accept="onDelete"
    />

    <div class="-image">
      <div class="h-100" v-if="imagePath">
        <!--  <div
          v-if="!isAccepted && !isSubmitted"
          v-ripple
          @click="deleteDialog = true"
          class="-icon"
        >
          <v-icon color="red"> mdi-delete </v-icon>
        </div> -->

        <img v-if="image" :src="image" alt="" />
        <v-skeleton-loader
          v-else-if="loading"
          class="h-100 w-100"
          type="image"
        />
      </div>

      <label
        v-ripple
        class="-card"
        :class="isValid && required && 'error--text'"
        v-else-if="!readonly"
        :for="uniqId"
      >
        <img width="45" src="../assets/icons/upload-file.svg" />
        <div v-text="$t('Upload an image')" />
      </label>

      <input
        @change="onChange"
        type="file"
        :id="uniqId"
        accept="image/x-png,image/jpeg"
      />
    </div>

    <div v-if="imagePath && image && !readonly">
      <div style="max-width: 400px" class="text-center">
        <label
          class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default accent cursor-pointer"
          :for="uniqId"
          v-ripple
        >
          <span class="v-btn__content" v-text="$t('Change the image')" />
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.-wrap {
  height: 100%;

  .-image {
    max-width: 400px;
    width: 100%;
    height: 80%;

    position: relative;

    .-icon {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background: #ebebeb;
      padding: 10px;
      border-bottom-right-radius: 70%;
      cursor: pointer;
    }

    > * {
      width: 100%;
    }

    overflow: hidden;

    img {
      height: 250px;
    }
  }
}

.-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  border: 1.5px solid #6c6c6c;
  border-radius: 5px;
  cursor: pointer;

  > div {
    padding-top: 5px;
  }

  &.error--text {
    border: 1.5px solid #ff00006b;
    box-shadow: inset 0 0 10px #ff00007d;
  }
}

input {
  display: none;
}
</style>
