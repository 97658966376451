import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "./i18n";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import sc from "./utils/staticConfig.js";
Vue.prototype.$file = (url) => `${sc.serverUrl}${url}`;

import "./filters";

import "./plugins/axios.js";
import "./plugins/inputmask.js";

import TelInput from "./components/TelInput/TelInput.vue";
Vue.component("tel-input", TelInput);

import VueMask from "v-mask";
Vue.use(VueMask);

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer, {
  defaultOptions: {
    navbar: false,
    title: false,
    toolbar: false,
  },
});

import masks from "./service/masks.js";
Vue.prototype.$masks = masks;

import rules from "./service/rules.js";
Vue.prototype.$rules = rules;

Vue.prototype.$toArray = (array) => (Array.isArray(array) ? array : []);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),

  beforeMount() {
    this.$store.dispatch("INIT_STATES");
  },
}).$mount("#app");
