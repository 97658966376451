<script>
import { mapGetters, mapActions } from "vuex";

import ChooseLocale from "../components/ChooseLocale.vue";

export default {
  name: "Admin",

  components: { ChooseLocale },

  data: () => ({
    drawer: true,
  }),

  computed: {
    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,

    menuItems: (vm) => vm.$toArray(vm.user.menus),
  },

  methods: {
    ...mapActions("auth", ["LOGOUT"]),

    onExit() {
      this.LOGOUT();
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar clipped-left app fixed>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-spacer />

      <!-- <div style="max-width: 275px">
        <v-list-item>
          <v-list-item-avatar color="rgb(154 154 154 / 22%)">
            <v-icon color="accent" large>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="title accent--text">
              {{ user.phone }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div> -->

      <choose-locale />

      <!-- <v-btn @click="onExit" class="transform-none" color="accent" text>
        {{ $t("Exit") }} <v-icon class="ml-1">mdi-login-variant</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      width="270"
      color="accent"
      dark
      app
      fixed
      clipped
    >
      <v-list>
        <v-list-item class="px-2">
          <div>
            <v-list-item-avatar>
              <v-icon color="white" large>mdi-account</v-icon>
            </v-list-item-avatar>
          </div>
          <div>
            <v-list-item-title v-text="user.phone" />
            <v-list-item-subtitle v-if="user.firstName">
              {{ user.firstName }}
              {{ user.lastName }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>

        <v-divider />

        <v-list-item
          :to="item.route"
          v-for="item in menuItems"
          :key="item.name"
        >
          <v-list-item-icon>
            <img width="24" :src="item.icon" alt="" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider />
        <v-list-item @click="onExit">
          <v-list-item-icon>
            <v-icon color="white">mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t('Exit')" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-main>
      <div class="pt-5">
        <router-view />
      </div>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
//
</style>
