<script>
export default {
  props: {
    text: String,
    value: Boolean,
  },

  data: () => ({
    // Reject?
    // Accept?
  }),

  computed: {
    dialog: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    onAccept() {
      this.$emit("accept");
      this.dialog = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card>
      <v-card-title class="title" v-text="text" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn color="green darken-1" text @click="onAccept"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
