<script>
import ModalsTemplate from "../ModalsTemplate.vue";

import { mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: {
    ModalsTemplate,
  },

  data: (vm) => ({
    object: {
      id: vm.data.id,
      password: null,
      passwordConfirmation: null,
    },

    model: true,

    confirmPasswordRules: [
      vm.$rules.required,
      (v) =>
        v == vm.object.password ||
        vm.$t("The password confirmation does not match."),
    ],
  }),

  watch: {
    model() {
      setTimeout(() => {
        this.$emit("close");
      }, 100);
    },
  },

  methods: {
    ...mapActions("admin-member", ["CHANGE_PASSWORD_MEMBER"]),

    onSend() {
      if (this.$refs.form.validate()) {
        this.CHANGE_PASSWORD_MEMBER(this.object).then((status) => {
          if (status != null) {
            this.$emit("refresh");
            this.model = false;
          }
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :title="$t('Change password')" :width="650" v-model="model">
    <v-form @submit.prevent="() => false" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field
            autofocus
            autocomplete="off"
            :label="$t('Password')"
            outlined
            v-model="object.password"
            required
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-text-field
            autocomplete="off"
            :label="$t('Confirm password')"
            outlined
            required
            v-model="object.passwordConfirmation"
            :rules="confirmPasswordRules"
          />
        </v-col>
      </v-row>
    </v-form>

    <template slot="actions">
      <div class="d-flex w-100">
        <v-spacer />
        <v-btn color="primary" class="btn-accent" @click="onSend">
          {{ $t("Save") }}
        </v-btn>
      </div>
    </template>
  </modals-template>
</template>
