<script>
export default {
  data: () => ({
    popup: true,
    examResult: null,
    answerPaperPicture: null,
    isLoading: false,
  }),

  created() {
    this.init();
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.examResult = await this.$axios.$get("/abiturient/exam/result");
      if (this.examResult.answerId) {
        if (this.examResult.keldi === 1) {
          const url = `/abiturient/application/exam/file/ANSWER_FILE/${this.examResult.answerId}`;
          this.answerPaperPicture = await this.$axios.$getImage(url);
        }
      }
      this.isLoading = false;
    },

    getName(item) {
      return item?.name;
    },
  },
};
</script>

<template>
  <v-container v-if="examResult">
    <div class="popup" v-if="popup">
      <div class="popup-inner text-center">
        <h2>{{ $t("attention") }}</h2>
        <p>{{ $t("objections") }}</p>
        <button class="popup-close" @click="popup = false">OK</button>
      </div>
    </div>
    <div class="pt-5">
      <h2>{{ $t("Exam results") }}</h2>

      <div class="-wrap">
        <v-row>
          <v-col cols="12" lg="8" offset-lg="2" class="pr-lg-0">
            <div class="my-card">
              <h4 class="text-center text-uppercase">
                {{ $t("results headline") }}
              </h4>
              <div class="pt-4">
                {{ $t("full name") }}: <b>{{ examResult.fullName }}</b>
              </div>
              <div class="pt-4">
                {{ $t("name of the university") }}:
                <b>{{ $t("university") }}</b>
              </div>
              <div v-if="examResult.keldi === '1'">
                <div class="pt-4" v-if="examResult.result === '1'">
                  <b style="color: green" class="text-uppercase">
                    {{ $t("recommended for enrollment") }}: </b
                  ><br />
                  <b>{{ examResult.actxt }}</b>
                </div>

                <div class="pt-4" v-else>
                  <b style="color: red" class="text-uppercase">
                    {{ $t("not recommended for enrollment") }}
                  </b>
                </div>
              </div>
              <div class="pt-4" v-else>
                <b style="color: red" class="text-uppercase">{{
                  $t("did not participate")
                }}</b>
              </div>

              <v-divider class="mt-4" />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.-wrap {
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.my-card {
  padding: 5px 20px;
  height: 100%;
}

.table-bordered {
  border: 1px solid #eaeaea;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  border-radius: 10px;
  width: 70%;
  background: #fff;
  padding: 32px;
}

.popup-inner h2 {
  font-size: 30px;
  color: red;
  margin-bottom: 15px;
}

.popup-inner p {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
}

.popup-close {
  padding: 12px 24px;
  background-color: lightcoral;
  color: white;
  border-radius: 10px;
  transition: 0.3s ease;
}

.popup-close:hover {
  background-color: red;
}

.popup-close:focus {
  background-color: red;
  outline: 7px solid #f8c2bc;
}
</style>
