import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  certificates: [],
  certificate: {},
});

export const mutations = {
  SET_CERTIFICATES(state, payload) {
    state.certificates = vm.$toArray(payload);
  },

  SET_CERTIFICATE_BY_ID(state, payload) {
    state.certificate = payload || {};
  },
};

export const actions = {
  async INIT_CERTIFICATES({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(
      `admin/foreign-language-proficiency${query}`
    );

    if (data) {
      commit("SET_CERTIFICATES", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async CERTIFICATE_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(
        `admin/foreign-language-proficiency/${id}`
      );

      if (Array.isArray(data)) {
        commit("SET_CERTIFICATE_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_CERTIFICATE(ctx, certificate) {
    let status = false;
    const url = "admin/foreign-language-proficiency";
    const method = certificate.id ? "$post" : "$put";

    status = await vm.$axios[method](url, certificate);

    return status;
  },

  async CERTIFICATE_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/foreign-language-proficiency/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },
  async DELETE_CERTIFICATE(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/foreign-language-proficiency/${id}`);

    return status;
  },
};

export const getters = {
  GET_CERTIFICATES: (state) => state.certificates,
  GET_CERTIFICATE_BY_ID: (state) => state.certificate,
};
