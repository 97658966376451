<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("admin-application", [
      "GET_APPLICATION",
      "GET_ADDITIONAL_FACULTIES",
    ]),
    application: (vm) => vm.GET_APPLICATION,
    faculties: (vm) => vm.GET_ADDITIONAL_FACULTIES,

    items() {
      const facultys = this.faculties;
      const appFacultys = this.application.additionalFaculty;

      return facultys.map((el) => {
        el.disabled = !!appFacultys.find((x) => x.id == el.id);
        return el;
      });
    },

    isDisable: (vm) => (index) => {
      if (index == 0) return false;

      const prev = index - 1;
      return !vm.application.additionalFaculty[prev]?.id;
    },
  },

  methods: {
    ...mapActions("admin-application", ["SET_APPLICATION"]),

    getLabel(index) {
      switch (index) {
        case 1:
          return "The first additional education direction";

        case 2:
          return "The second direction is additional education";

        case 3:
          return "The third direction of additional education";

        case 4:
          return "The fourth additional education direction";

        case 5:
          return "The fifth direction of additional education";

        case 6:
          return "The sixth additional education direction";

        case 7:
          return "Seventh additional education";

        case 8:
          return "Eighth additional education";
      }
    },
  },
};
</script>

<template>
  <div class="add-faculty-select">
    <v-select
      v-for="(item, index) in application.additionalFaculty"
      :key="index"
      :items="items"
      v-model="item.id"
      item-text="name"
      item-value="id"
      autocomplete="off"
      :disabled="isDisable(index)"
      :label="$t(getLabel(index + 1))"
      dense
      outlined
    />
  </div>
</template>

<style></style>
