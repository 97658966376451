<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },

  data: () => ({
    id: 0,
    allowance: null,
    isLoading: false,
  }),

  created() {
    this.id = Number(this.$route.params.id);
    this.init();
  },

  methods: {
    async init() {
      this.isLoading = true;
      const data = await this.$axios.$get(`/admin/ruxsatnoma/${this.id}`);
      this.isLoading = false;
      this.allowance = data?.fullName ? data : null;
    },

    name(v) {
      return v?.name || null;
    },
  },
};
</script>

<template>
  <v-container>
    <div v-if="allowance" class="d-flex justify-content-center">
      <div class="d-inline-block">
        <div class="d-flex py-5">
          <v-spacer />

          <v-btn
            color="primary"
            class="transform-none"
            @click="$refs.html2Pdf.generatePdf()"
          >
            {{ $t("Download pdf") }}
          </v-btn>
        </div>
        <div class="pdf-wrap" style="padding-left: 14px">
          <vue-html2pdf
            :manual-pagination="true"
            :paginate-elements-by-height="842"
            :enable-download="true"
            :float-layout="false"
            :show-layout="true"
            :preview-modal="false"
            :filename="allowance.fullName"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <div class="pdf-content">
                <div class="pdf-header">
                  <div>
                    <img
                      class="align-content-center rasmcenter"
                      src="../../assets/img/logo.png"
                      alt=""
                      width="150"
                    />
                  </div>
                  <div class="pdf-title">
                    ENTRANCE EXAM REGISTRATION DOCUMENT FOR<br />
                    2024-2025 ACADEMIC YEAR<br />
                    ПРОПУСК К ВСТУПИТЕЛЬНЫМ ЭКЗАМЕНАМ<br />
                    2024-2025 УЧЕБНОГО ГОДА<br />
                    2024-2025 O’QUV YILI UCHUN KIRISH IMTIHON RUXSATNOMASI
                  </div>
                </div>

                <table border="1" class="pdf-data pdf-table">
                  <tbody>
                    <tr>
                      <td>NAME/Ф.И.О/F.I.SH:</td>
                      <td
                        colspan="3"
                        class="text-upper"
                        v-text="allowance.fullName"
                      />
                    </tr>
                    <tr>
                      <td>FACULTY/ФАКУЛЬТЕТ/TA’LIM YO’NALISH:</td>
                      <td
                        colspan="3"
                        class="text-upper"
                        v-text="name(allowance.educationFaculty)"
                      />
                    </tr>
                    <tr>
                      <td>DATE OF EXAM/ДАТА ЭКЗАМЕНА/IMTIHON KUNI:</td>
                      <td class="td-text-color" v-text="allowance.day1" />
                      <td>PASSPORT NUMBER/СЕРИЯ ПАСПОРТА/PASPORT RAQAMI:</td>
                      <td v-text="allowance.passportNumber" />
                    </tr>
                    <tr>
                      <td style="text-transform: uppercase">
                        Exam entry time/<br />
                        ВРЕМЯ ВХОДА НА ЭКЗАМЕН/
                        <br />IMTIHONGA KIRISH VAQTI:
                      </td>
                      <td v-text="allowance.smena1" />
                      <td>FLOOR/ЭТАЖ/QAVAT:</td>
                      <td v-text="allowance.floor1" />
                    </tr>
                    <tr v-if="allowance.day2">
                      <td>DATE OF EXAM/ДАТА ЭКЗАМЕНА/ IMTIHON KUNI:</td>
                      <td
                        class="td-text-color"
                        colspan="3"
                        v-text="allowance.day2"
                      />
                    </tr>
                    <tr v-if="allowance.floor2 && allowance.smena2">
                      <td>FLOOR/ЭТАЖ/QAVAT:</td>
                      <td v-text="allowance.floor2" />
                      <td>SHIFT/СМЕНА/SMENA:</td>
                      <td v-text="allowance.smena2" />
                    </tr>
                    <tr v-if="allowance.day3">
                      <td>DATE OF EXAM/ДАТА ЭКЗАМЕНА/ IMTIHON KUNI:</td>
                      <td
                        class="td-text-color"
                        colspan="3"
                        v-text="allowance.day3"
                      />
                    </tr>
                    <tr v-if="allowance.floor3 && allowance.smena3">
                      <td>FLOOR/ЭТАЖ/QAVAT:</td>
                      <td v-text="allowance.floor3" />
                      <td>SHIFT/СМЕНА/SMENA:</td>
                      <td v-text="allowance.smena3" />
                    </tr>
                    <tr>
                      <td>LANGUAGE/ЯЗЫК/TIL:</td>
                      <td v-text="name(allowance.educationLanguage)" />
                      <td>GROUP/ГРУППА/GURUH:</td>
                      <td v-text="allowance.group" />
                    </tr>
                  </tbody>
                </table>

                <!--                <div class="selected-facultys-wrap">-->
                <!--                  <h6 class="selected-facultys-title">-->
                <!--                    Tanlangan yo'nalishlar:-->
                <!--                  </h6>-->
                <!--                  <div class="selected-facultys">-->
                <!--                    <div-->
                <!--                      class="d-flex"-->
                <!--                      v-for="(item, index) in $toArray(-->
                <!--                        allowance.additionalFaculty-->
                <!--                      )"-->
                <!--                      :key="item.id"-->
                <!--                    >-->
                <!--                      <span>{{ index + 1 }}.</span>-->
                <!--                      <div style="padding-left: 5px" v-text="item.name" />-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="note-wrap">
                  <div class="note">
                    <h4 class="text-center">MUHIM ESLATMA</h4>
                    <div class="text-center">
                      <b>
                        Quyidagi hollarda abituriyent test sinovlariga
                        kiritilmaydi:
                      </b>
                    </div>

                    <div class="pdf-ul">
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>O’z vaqtida kelmasa</div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Shaxsini tasdiqlovchi hujjat (asli) va ruxsatnoma
                          mavjud bo'lmasa
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Yonida mobil telefon yoki boshqa elektron vositalari,
                          turli ma’lumotlar, shpargalkalar mavjud bo’lsa, sumka
                          va boshqa imtihonga xalaqit berishi mumkin bo’lgan
                          narsalar aniqlansa
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <b>
                        Quyidagi hollarda abituriyent test sinovlaridan
                        chetlashtiriladi:
                      </b>
                    </div>
                    <div class="pdf-ul">
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Qabul komissiya a’zolari va kuzatuvchilarning tegishli
                          ko’rsatmalariga amal qilmasa
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Boshqa abituriyentlarga yordam bersa yoki ulardan
                          yordam so’rasa
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Abituriyentda turli ma’lumotlar (shpargalka), mobil
                          telefon va elektron qurilmalar aniqlansa (ishlatmagan
                          bo’lsa ham)
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Test materiallarini boshqa abituriyentlarga ko’rsatsa
                          yoki boshqa abituriyentlarning test materiallariga
                          qarasa
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Test jarayonida gaplashsa, boshqa abituriyentlarga
                          halaqit bersa
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Test sinovlari materiallarini va javoblar varaqasini
                          yirtsa, boshqalarga uzatsa, olib chiqib ketsa
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="note">
                    <h4 class="text-center">ВАЖНОЕ НАПОМИНАНИЕ</h4>
                    <div class="text-center">
                      <b>
                        Абитуриент не допускается до тестовых испытаний в
                        следующих случаях:
                      </b>
                    </div>
                    <div class="pdf-ul">
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>При опоздании</div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          При отсутствии документа (оригинал) удостоверяющего
                          личность и данного пропуска
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          При наличии с собой мобильного телефона или иного
                          электронного устройства, справочных материалов,
                          шпаргалок
                        </div>
                      </div>
                    </div>

                    <div class="text-center">
                      <b>
                        Абитуриент отстраняется от тестовых испытаний в
                        следующих случаях:
                      </b>
                    </div>
                    <div class="pdf-ul">
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если не будет следовать соответствующим инструкциям
                          членов Приёмной комиссии и наблюдателей
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если будет помогать другим абитуриентам или просить
                          помощь у них
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если у абитуриента обнаружатся шпаргалки, мобильный
                          телефон и/или другие электронные устройства (даже если
                          им/ими не пользовались)
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если будет показывать тестовые материалы другим
                          абитуриентам или будет просматривать их работы
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если будет разговаривать во время тестирования и
                          мешать другим абитуриентам
                        </div>
                      </div>
                      <div class="pdf-li">
                        <div class="ul-circle" />
                        <div>
                          Если будет рвать тестовые материалы и лист ответов,
                          передавать их другим лицам и выносить их из зала
                          тестирования
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="allowance.branch.id === 1"
                  class="pdf-title"
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-bottom: 5px;
                    color: #555;
                  "
                >
                  TOSHKENT KIMYO XALQARO UNIVERSITETI<br />
                  KIMYO INTERNATIONAL UNIVERSITY IN TASHKENT<br />
                  Manzil: Toshkent shahar, Shota Rustaveli ko‘chasi, 156-uy
                </div>
                <div
                  v-else-if="allowance.branch.id === 3"
                  class="pdf-title"
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-bottom: 5px;
                    color: #555;
                  "
                >
                  TOSHKENT KIMYO XALQARO UNIVERSITETI SAMARQAND FILIALI<br />
                  KIMYO INTERNATIONAL UNIVERSITY IN TASHKENT SAMARKAND BRANCH
                  <br />
                  Manzil: Samarqand shahar, H.Abdullayev ko`chasi, 63-uy
                </div>
                <div
                  v-else-if="allowance.branch.id === 4"
                  class="pdf-title"
                  style="
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-bottom: 5px;
                    color: #555;
                  "
                >
                  TOSHKENT KIMYO XALQARO UNIVERSITETI NAMANGAN FILIALI <br />
                  KIMYO INTERNATIONAL UNIVERSITY IN TASHKENT NAMANGAN BRANCH
                  <br />
                  Manzil: Namangan shahar, Chortoq ko'chasi, 75A - uy
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
    </div>

    <h3 v-else-if="!isLoading" class="text-center1 pt-5">
      {{ "Imtihondan 3 kun oldin ruxsatnoma ma`lumotlari keladi" }}
    </h3>
  </v-container>
</template>

<style lang="scss" scoped>
.pdf-wrap {
  border: 1px solid #dee2e6;
  display: inline-block;
  box-shadow: 0 0 8px #8080803b;
}

.pdf-content {
  padding: 20px 30px 20px 20px;

  &,
  * {
    font-family: "Open Sans", sans-serif !important;
  }
}

.pdf-header {
  display: flex;
  align-items: center;

  .pdf-title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    flex: 1;
  }
}

.pdf-data {
  font-weight: bold;
  font-size: 16px;
  width: 100%;

  margin-top: 20px;
  margin-bottom: 30px;

  td {
    padding: 4px 3px 3px 5px;
  }
}

.selected-facultys-wrap {
  margin: 10px 10px 20px 20px;
}

.text-upper {
  text-transform: uppercase;
}

.selected-facultys-title {
  font-size: 18px;
  font-weight: bold;
}

.selected-facultys {
  padding-left: 10px;
}

.note-wrap {
  display: flex;
  font-size: 14px;

  .note {
    padding: 12px;

    border: 1px solid black;

    &:last-child {
      border-left: none;
    }
  }
}

.text-center {
  text-align: center;
}

.td-text-color {
  color: #f44336;
}

.text-center1 {
  margin-top: 50px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
}

.pdf-li {
  display: flex;
  align-items: flex-start;

  .ul-circle {
    height: 5px;
    width: 5px;
    max-height: 5px;
    max-width: 5px;
    min-height: 5px;
    min-width: 5px;

    margin-top: 6px;

    border-radius: 50%;

    background-color: black;
  }

  > div:last-child {
    flex: 1;

    padding-left: 8px;
  }
}
</style>
