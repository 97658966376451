export default [
  {
    "name-uz": "Erkak",
    "name-ru": "Мужчина",
    "name-en": "Male",
    id: "MALE",
  },
  {
    "name-uz": "Ayol",
    "name-ru": "Женщина",
    "name-en": "Female",
    id: "FEMALE",
  },
];
