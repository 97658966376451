import Vue from "vue";

export const tel = (v) => {
  let vs = `${v || ""}`;
  if (vs.length == 12) {
    const value = vs.replace("998", "");
    const code = value.substr(0, 2);
    const num1 = value.substr(2, 3);
    const num2 = value.substr(5, 2);
    const num3 = value.substr(7, 2);
    return `+998(${code})  ${num1}-${num2}-${num3}`;
  } else {
    return vs;
  }
};

Vue.prototype.$tel = tel;

Vue.filter("tel", tel);
