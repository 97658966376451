import { render, staticRenderFns } from "./MemberChangePassword.vue?vue&type=template&id=13fda3eb"
import script from "./MemberChangePassword.vue?vue&type=script&lang=js"
export * from "./MemberChangePassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports