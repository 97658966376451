var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"search-wrapper"},[_c('v-text-field',{staticClass:"search-input",attrs:{"placeholder":_vm.$t('Search...'),"append-icon":"mdi-magnify","dense":"","hide-details":"","outlined":"","clearable":""},on:{"click:append":_vm.getItems,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getItems.apply(null, arguments)},"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canExport)?_c('v-btn',{staticClass:"ml-2 transform-none",attrs:{"loading":_vm.isExport,"color":"accent"},on:{"click":_vm.onExport}},[_vm._v(" "+_vm._s(_vm.$t("Export to excel"))+" ")]):_vm._e()],1)]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"mobile-breakpoint":0,"height":_vm.tableHeight,"loading":_vm.isLoading,"hide-default-footer":"","headers":_vm.branch === -1
        ? [
            {
              text: _vm.$t('Full name'),
              value: 'fullName',
            },
            {
              text: _vm.$t('Date'),
              value: 'registrationDate',
            },
            {
              text: _vm.$t('Branch'),
              value: 'branch',
            },
            {
              text: _vm.$t('Education direction'),
              value: 'faculty',
            },
            {
              text: _vm.$t('Phone'),
              value: 'mobilePhone',
            },
            {
              text: _vm.$t('Passport'),
              value: 'passportNumber',
            },
          ]
        : [
            {
              text: _vm.$t('Full name'),
              value: 'fullName',
            },
            {
              text: _vm.$t('Date'),
              value: 'registrationDate',
            },
            {
              text: _vm.$t('Education direction'),
              value: 'faculty',
            },
            {
              text: _vm.$t('Phone'),
              value: 'mobilePhone',
            },
            {
              text: _vm.$t('Passport'),
              value: 'passportNumber',
            },
          ],"fixed-header":"","items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"item-key":"id","items-per-page":_vm.itemsPerPage,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('tbody',[_vm._l((props.items),function(item){return _c('tr',{key:item.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onOpen(item)}}},[_c('td',{domProps:{"textContent":_vm._s(_vm.getFullName(item))}}),_c('td',{domProps:{"textContent":_vm._s(_vm.$date(item.registrationDate))}}),(_vm.branch === -1)?_c('td',{domProps:{"textContent":_vm._s(item.branch)}}):_vm._e(),_c('td',{domProps:{"textContent":_vm._s(_vm.getName(item.faculty))}}),_c('td',{domProps:{"textContent":_vm._s(_vm.$tel(item.mobilePhone))}}),_c('td',{domProps:{"textContent":_vm._s(item.passportNumber)}})])}),(!props.items.length && !_vm.isLoading)?_c('tr',[_c('td',{staticClass:"text-center py-4",attrs:{"colspan":props.headers.length + 1}},[_vm._v(" "+_vm._s(_vm.$t("No data"))+" ")])]):_vm._e()],2)]}}])}),_c('div',{staticClass:"pt-2 pb-2"},[_c('v-pagination',{attrs:{"total-visible":10,"length":_vm.paginationCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }