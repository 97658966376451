<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: {
    data: Object,
  },

  watch: {
    data() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      new Chart(this.$refs.canvas.getContext("2d"), this.data);
    },
  },
};
</script>

<template>
  <canvas ref="canvas" />
</template>
