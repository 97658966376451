import Vue from "vue";
const vm = Vue.prototype;

export const state = () => ({
  countries: [],
  country: {},

  countries_all: [],
});

export const mutations = {
  SET_COUNTRIES(state, payload) {
    state.countries = vm.$toArray(payload);
  },

  SET_COUNTRY_BY_ID(state, payload) {
    state.country = payload || {};
  },

  SET_COUNTRIES_ALL(state, payload) {
    state.countries_all = vm.$toArray(payload);
  },
};

export const actions = {
  async INIT_COUNTRIES({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/country${query}`);
    if (data) {
      commit("SET_COUNTRIES", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async COUNTRY_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/country/${id}`);
      if (Array.isArray(data)) {
        commit("SET_COUNTRY_BY_ID", data);

        status = true;
      }
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_COUNTRY(ctx, country) {
    let status = false;
    const url = "admin/country";
    const method = country.id ? "$post" : "$put";

    status = await vm.$axios[method](url, country);

    return status;
  },

  async COUNTRY_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/country/${id}/`;
    const method = !active ? "deactivation" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },

  async INIT_COUNTRIES_ALL({ commit }) {
    const data = await vm.$axios.$get(`admin/country/all`);
    if (data) {
      commit("SET_COUNTRIES_ALL", data);
    }
  },
  async DELETE_COUNTRY(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/country/${id}`);

    return status;
  },
};

export const getters = {
  GET_COUNTRIES: (state) => state.countries,
  GET_COUNTRY_BY_ID: (state) => state.country,
  GET_COUNTRIES_ALL: (state) => state.countries_all,
};
