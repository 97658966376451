<script>
import { mapActions, mapGetters } from "vuex";

import genders from "../service/genders";
import AdminAppShowDetail from "./AdminAppShowDetail.vue";
import AdminAppShowPhoto from "./AdminAppShowPhoto.vue";
import AppConfirm from "./modals/app-confirm.vue";

export default {
  components: { AdminAppShowDetail, AdminAppShowPhoto, AppConfirm },
  data: () => ({
    genders,

    photo: null,

    acceptDialog: false,
    rejectDialog: false,
  }),

  computed: {
    ...mapGetters("admin-applications", ["GET_APPLICATION"]),
    application: (vm) => vm.GET_APPLICATION,
    disableReject() {
      const values = Object.values(this.application.reviews);
      return !values.filter((x) => `${x || ""}`.trim()).length;
    },

    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,

    isReadonly: (vm) => vm.user.role === "ADMIN_OBSERVER",
  },

  methods: {
    ...mapActions("admin-applications", [
      "ACCEPT_APPLICATION",
      "REJECT_APPLICATION",
    ]),

    getGender(id) {
      return this.genders.find((x) => x.id === id) || {};
    },

    onAccept() {
      this.ACCEPT_APPLICATION({ id: this.application.id }).then((status) => {
        if (status) {
          this.$router.push("/admin");
        }
      });
    },

    onRejects() {
      const params = {
        id: this.application.id,
        reviews: this.application.reviews,
      };
      this.REJECT_APPLICATION(params).then((status) => {
        if (status) {
          this.$router.push("/admin");
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <v-form
      :readonly="isReadonly"
      @submit.prevent="() => false"
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('First name')"
              :text="application.firstName"
              v-model="application.reviews.firstName"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Last name')"
              :text="application.lastName"
              v-model="application.reviews.lastName"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Middle name')"
              :text="application.middleName"
              v-model="application.reviews.middleName"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Gender')"
              :text="getGender(application.gender)[`name-${$i18n.locale}`]"
              v-model="application.reviews.gender"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Date of birth')"
              :text="application.birthDate"
              v-model="application.reviews.birthDate"
            />
          </div>
        </v-col>

        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <div>
              <div class="-title" v-text="$t('Country')" />
              <div class="-text" v-text="application.country.name" />
            </div>
            <div class="pt-4">
              <div class="-title" v-text="$t('Region')" />
              <div class="-text" v-text="application.region.name" />
            </div>
            <div class="pt-4">
              <div class="-title" v-text="$t('City / District')" />
              <div class="-text" v-text="application.city.name" />
            </div>

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Address')"
              :text="application.addressLine"
              v-model="application.reviews.addressLine"
            />
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <admin-app-show-detail
              class="pt-4"
              :title="$t('Citizenship')"
              :text="application.citizenship.name"
              v-model="application.reviews.citizenship"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Nationality')"
              :text="application.nationality"
              v-model="application.reviews.nationality"
            />
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <admin-app-show-detail
            :title="$t('Photo')"
            v-model="application.reviews.photo"
          />
          <admin-app-show-photo
            class="pt-2"
            v-if="application.photo"
            :url="$file(application.photo)"
          />
        </v-col>
      </v-row>

      <div class="pt-10" />
      <div class="text-center">
        <img src="@/assets/icons/devider.svg" alt="" />
      </div>
      <div class="pt-10" />

      <v-row>
        <v-col cols="12" md="3">
          <admin-app-show-detail
            :title="$t('Copy of the passport')"
            v-model="application.reviews.passportPhoto"
          />
          <admin-app-show-photo
            class="pt-2"
            v-if="application.passportPhoto"
            :url="$file(application.passportPhoto)"
          />
        </v-col>
        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('Passport series and number')"
              :text="application.passportNumber"
              v-model="application.reviews.passportNumber"
            />
            <admin-app-show-detail
              :title="$t('PINFL')"
              :text="application.pinfl"
              v-model="application.reviews.pinfl"
            />
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('Home phone number')"
              :text="application.homePhone"
              v-model="application.reviews.homePhone"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Personal phone number')"
              :text="application.mobilePhone"
              v-model="application.reviews.mobilePhone"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Father\'s phone number')"
              :text="application.fatherPhone || '—'"
              v-model="application.reviews.fatherPhone"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Mother\'s phone number')"
              :text="application.motherPhone || '—'"
              v-model="application.reviews.motherPhone"
            />
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('Disability status')"
              :text="application.disability.name || '—'"
              v-model="application.reviews.disability"
            />

            <admin-app-show-detail
              class="pt-4"
              :title="$t('Disability note')"
              :text="application.disabilityNote || '—'"
              v-model="application.reviews.disabilityNote"
            />
          </div>
        </v-col>
      </v-row>

      <div class="pt-10" />
      <div class="text-center">
        <img src="@/assets/icons/devider.svg" alt="" />
      </div>
      <div class="pt-10" />

      <v-row>
        <v-col cols="12" md="4">
          <div class="-data-wrap">
            <div>
              <admin-app-show-detail
                :title="$t('Graduated school / college / lyceum')"
                class="-title"
                :text="application.schoolName"
                v-model="application.reviews.schoolName"
              />
            </div>

            <admin-app-show-detail
              class="pt-4"
              :title="$t('School type')"
              :text="application.schoolType.name"
              v-model="application.reviews.schoolType"
            />
            <admin-app-show-detail
              class="pt-4"
              :title="$t('Year of graduation')"
              :text="application.graduationYear"
              v-model="application.reviews.graduationYear"
            />
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="-data-wrap">
            <admin-app-show-detail
              class="pt-4"
              :title="$t('Diploma / Certificate number')"
              :text="application.highSchoolDiplomaNumber"
              v-model="application.reviews.highSchoolDiplomaNumber"
            />
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <admin-app-show-detail
            v-model="application.reviews.highSchoolDiplomaPhoto"
            :title="$t('Copy of the Certificate / Diploma')"
          />
          <admin-app-show-photo
            class="pt-2"
            v-if="application.highSchoolDiplomaPhoto"
            :url="$file(application.highSchoolDiplomaPhoto)"
          />
        </v-col>
      </v-row>

      <div class="pt-10" />
      <div class="text-center">
        <img src="@/assets/icons/devider.svg" alt="" />
      </div>
      <div class="pt-10" />

      <v-row>
        <v-col cols="12" md="4">
          <admin-app-show-detail
            v-model="application.reviews.foreignLanguageProficiencyPhoto"
            :title="$t('The result of the English language')"
          />
          <admin-app-show-photo
            class="pt-2"
            v-if="application.foreignLanguageProficiencyPhoto"
            :url="$file(application.foreignLanguageProficiencyPhoto)"
          />
          <div v-else>—</div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('Type')"
              :text="application.foreignLanguageProficiency.name || '—'"
              v-model="application.reviews.foreignLanguageProficiency"
            />
            <div
              v-if="
                application.foreignLanguageProficiency.id &&
                application.foreignLanguageProficiency.id === 6
              "
            >
              <admin-app-show-detail
                :title="$t('name_certificate')"
                class="pt-4"
                :text="application.certificateOther || '—'"
                v-model="application.reviews.certificateOther"
              />
            </div>

            <admin-app-show-detail
              :title="$t('Score')"
              class="pt-4"
              :text="application.score || '—'"
              v-model="application.reviews.score"
            />

            <admin-app-show-detail
              :title="$t('Certificate number')"
              class="pt-4"
              :text="application.certificateNumber || '—'"
              v-model="application.reviews.certificateNumber"
            />
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="-data-wrap">
            <admin-app-show-detail
              :title="$t('Branch')"
              class="pt-4"
              :text="application.branch.name"
              v-model="application.reviews.branch"
            />
            <admin-app-show-detail
              :title="$t('Field of study')"
              class="pt-4"
              :text="application.educationFaculty.name"
              v-model="application.reviews.educationFaculty"
            />
            <admin-app-show-detail
              :title="$t('Type of education')"
              class="pt-4"
              :text="application.educationType.name"
              v-model="application.reviews.educationType"
            />
            <admin-app-show-detail
              :title="$t('Language of instruction')"
              class="pt-4"
              :text="application.educationLanguage.name"
              v-model="application.reviews.educationLanguage"
            />

            <div class="pt-4">
              <admin-app-show-detail
                v-model="application.reviews.additionalFaculties"
                :title="$t('Additional study areas')"
              />
              <div
                v-for="(item, index) in application.additionalFaculty"
                :key="index"
                class="-text"
                v-text="`${index + 1} - ${item.name}`"
              />

              <div v-if="!application.additionalFaculty.length">—</div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!isReadonly">
        <v-col cols="12">
          <div class="d-flex pt-10">
            <v-spacer />

            <v-btn
              :disabled="disableReject"
              @click="rejectDialog = true"
              color="error"
            >
              {{ $t("Reject") }}
            </v-btn>
            <v-btn
              :disabled="application.status == 'ACCEPTED'"
              @click="acceptDialog = true"
              color="accent"
              class="ml-3"
            >
              {{ $t("Accept") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <app-confirm
      v-model="acceptDialog"
      :text="$t('Accept?')"
      @accept="onAccept"
    />

    <app-confirm
      v-model="rejectDialog"
      :text="$t('Reject?')"
      @accept="onRejects"
    />
  </div>
</template>

<style lang="scss" scoped>
.-data-wrap {
  height: 100%;
  border-left: 1px solid rgba(128, 128, 128, 0.699);

  padding-left: 15px;
}

.-title {
  font-size: 14px;
}

.-text {
  font-weight: 500;
}
</style>
