<script>
import { mapActions, mapGetters } from "vuex";

import sizeVue from "@/mixins/size.vue";
import CountryAdd from "@/components/modals/CountryAdd.vue";
import deleteModal from "@/components/modals/deleteModal";

export default {
  components: { CountryAdd, deleteModal },
  data: () => ({
    isLoading: false,

    options: {},
    totalItemsCount: 0,
    itemsPerPage: 50,
    rowsPerPage: [25, 50, 100],

    search: null,

    isAddModal: false,
    isDeleteModal: false,

    selected: {},
  }),

  mixins: [sizeVue],

  computed: {
    tableHeight: (vm) => vm.size.height - 280,

    ...mapGetters("admin-countries", ["GET_COUNTRIES"]),
    items: (vm) => vm.GET_COUNTRIES,
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("admin-countries", [
      "INIT_COUNTRIES",
      "COUNTRY_STATUS",
      "DELETE_COUNTRY",
    ]),

    getItems() {
      if (this.isLoading) return;
      this.isLoading = true;

      this.options.search = this.search;

      this.INIT_COUNTRIES(this.options).then((totalItemsCount) => {
        this.totalItemsCount = totalItemsCount;
        this.isLoading = false;
      });
    },

    onAddModel() {
      this.selected = {};

      this.$nextTick(() => {
        this.isAddModal = true;
      });
    },

    actionDelete(id) {
      this.isLoading = true;
      this.DELETE_COUNTRY(id)
        .then(() => {
          this.isLoading = false;
          this.getItems();
          this.isDeleteModal = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    onEdit({ item = {} }) {
      this.selected = item;

      this.$nextTick(() => {
        this.isAddModal = true;
      });
    },

    onDelete({ item = {} }) {
      this.selected = item;
      this.$nextTick(() => {
        this.isDeleteModal = true;
      });
    },

    onSwitch({ item = {} }) {
      if (this.isLoading) return;

      this.isLoading = false;
      this.COUNTRY_STATUS(item).then(() => {
        this.isLoading = false;
        this.$nextTick(() => {
          this.getItems();
        });
      });
    },
  },
};
</script>

<template>
  <v-container fluid>
    <country-add
      v-if="isAddModal"
      @close="isAddModal = false"
      @refresh="getItems"
      :data="selected"
    />
    <delete-modal
      v-if="isDeleteModal"
      @close="isDeleteModal = false"
      @action="() => actionDelete(selected.id)"
    />

    <v-card class="pa-5">
      <div class="d-flex pb-5">
        <div class="title" v-text="$t('Countries')" />

        <v-spacer />
        <v-btn @click="onAddModel">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn :loading="isLoading" @click="getItems" class="ml-5">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <div class="pl-5" style="width: 300px">
          <v-text-field
            :placeholder="$t('Search...')"
            append-icon="mdi-magnify"
            v-model="search"
            dense
            hide-details
            outlined
            clearable
            @click:append="getItems"
            @keyup.enter="getItems"
            @click:clear="getItems"
          />
        </div>
      </div>

      <v-data-table
        :mobile-breakpoint="0"
        :height="tableHeight"
        :loading="isLoading"
        :headers="[
          {
            text: '№',
            value: 'row-number',
            width: 20,
            sortable: false,
          },
          {
            text: $t('Name uz'),
            value: 'name->uz',
          },
          {
            text: $t('Name ru'),
            value: 'name->ru',
          },
          {
            text: $t('Name en'),
            value: 'name->en',
          },
          {
            text: $t('Placeholder'),
            value: 'placeholder',
          },
          {
            text: '',
            value: 'delete',
            width: 20,
            sortable: false,
          },
          {
            text: '',
            value: 'edit',
            width: 20,
            sortable: false,
          },
          {
            text: '',
            value: 'block',
            width: 20,
            sortable: false,
          },
        ]"
        fixed-header
        :items="items"
        :options.sync="options"
        :server-items-length="totalItemsCount"
        item-key="id"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: rowsPerPage }"
        class="elevation-0"
      >
        <template v-slot:body="props">
          <tbody>
            <tr :key="item.id" v-for="(item, index) in props.items">
              <td v-text="index + 1" />
              <td v-text="item.names.uz" />
              <td v-text="item.names.ru" />
              <td v-text="item.names.en" />
              <td v-text="item.placeholder" />
              <td
                v-ripple
                @click="onDelete({ item })"
                class="cursor-pointer px-2"
              >
                <v-icon :title="$t('Delete')"> mdi-delete</v-icon>
              </td>
              <td
                v-ripple
                @click="onEdit({ item })"
                class="cursor-pointer px-2"
              >
                <v-icon :title="$t('Edit')"> mdi-pencil</v-icon>
              </td>
              <td @click="onSwitch({ item })" class="pr-0">
                <v-switch
                  :loading="isLoading"
                  hide-details
                  class="mt-0"
                  v-model="item.active"
                  inset
                />
              </td>
            </tr>
            <tr v-if="!props.items.length && !isLoading">
              <td :colspan="props.headers.length + 1" class="text-center py-4">
                {{ $t("No data") }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
