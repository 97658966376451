export default {
  phone: {
    mask: "+\\9\\98 99 999 99 99",
    autoUnmask: true,
    showMaskOnHover: false,
  },

  text: {
    regex: "[a-zA-Z'` ]*",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  date: {
    alias: "datetime",
    inputFormat: "dd.mm.yyyy",
    placeholder: "дд.мм.гггг",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  number: {
    alias: "numeric",
    groupSeparator: " ",
    showMaskOnHover: false,
    autoUnmask: true,
    rightAlign: false,
  },

  number2: {
    alias: "numeric",
    autoUnmask: true,
    rightAlign: false,
  },

  pasport: {
    mask: "aa 9999999",
    casing: "upper",
    autoUnmask: true,
  },

  email: {
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
    greedy: false,
    onBeforePaste: (pastedValue) => {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      "*": {
        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
        cardinality: 1,
        casing: "lower",
      },
    },
  },
};
