import Vue from "vue";

const vm = Vue.prototype;

export const state = () => ({
  members: [],
  member: {},
  roles: [],
  branches: [],
});

export const mutations = {
  SET_MEMBERS(state, payload) {
    state.members = vm.$toArray(payload);
  },

  SET_ADMIN_BRANCHES(state, payload) {
    state.branches = vm.$toArray(payload);
  },

  SET_ROLES(state, payload) {
    state.roles = vm.$toArray(payload);
  },

  SET_MEMBER_BY_ID(state, payload) {
    state.member = payload || {};
  },
};

export const actions = {
  async INIT_MEMBERS({ commit }, options) {
    const { itemsPerPage, search = "", sortBy = [], sortDesc = [] } = options;

    const page = Number(options.page - 1) || 0;
    const sortField = sortBy.length ? sortBy[0] : "";
    const sortOrder = sortDesc.length && sortDesc[0] ? "desc" : "asc";

    const senc = search ? encodeURI(search) : null;

    const query = `?page=${page}&size=${itemsPerPage}&name=${senc}&sortField=${sortField}&sortOrder=${sortOrder}`;
    let totalItemsCount = 0;

    const data = await vm.$axios.$get(`admin/admin-member${query}`);

    if (data) {
      commit("SET_MEMBERS", data.content);

      totalItemsCount = data.totalElements;
    }

    return totalItemsCount;
  },

  async INIT_ROLES({ commit }) {
    const data = await vm.$axios.$get(`admin/admin-member/roles`);
    if (data) {
      commit(
        "SET_ROLES",
        vm.$toArray(data).map((item) => ({ id: item, name: item }))
      );
    }
  },

  async INIT_ADMIN_BRANCHES({ commit }) {
    const data = await vm.$axios.$get(`admin/admin-member/branches`);
    if (data) {
      commit("SET_ADMIN_BRANCHES", data);
    }
  },

  async MEMBER_BY_ID({ commit }, { id }) {
    let status = false;

    try {
      const data = await vm.$axios.$get(`admin/admin-member/${id}`);

      commit("SET_MEMBER_BY_ID", data);

      status = true;
    } catch (error) {
      //
    }

    return status;
  },

  async ADD_MEMBER(ctx, member) {
    let status = false;
    const url = "admin/admin-member";
    const method = member.id ? "$post" : "$put";

    status = await vm.$axios[method](url, member);

    return status;
  },

  async MEMBER_STATUS(ctx, { id, active }) {
    let status = false;

    const url = `admin/admin-member/${id}/`;
    const method = !active ? "blocking" : "activation";

    status = await vm.$axios.$post(url + method);

    return status;
  },

  async CHANGE_PASSWORD_MEMBER(ctx, { id, password, passwordConfirmation }) {
    let status = false;
    const params = { password, passwordConfirmation };
    status = await vm.$axios.$post(`admin/admin-member/${id}/password`, params);

    return status;
  },
  async DELETE_MEMBER(ctx, id) {
    let status = false;
    status = await vm.$axios.delete(`admin/admin-member/${id}`);

    return status;
  },
};

export const getters = {
  GET_MEMBERS: (state) => state.members,
  GET_ROLES: (state) => state.roles,
  GET_ADMIN_BRANCHES: (state) => state.branches,
  GET_MEMBER_BY_ID: (state) => state.member,
};
