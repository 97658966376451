<script>
import { mapActions } from "vuex";
import TelInputAuth from "@/components/TelInput/TelInputAuth.vue";

import AuthTemplate from "@/components/AuthTemplate.vue";

export default {
  name: "Login",

  components: { AuthTemplate, TelInputAuth },

  data: () => ({
    login: {
      username: "",
      password: null,
    },

    isShow: false,

    isLoading: false,

    message: null,
  }),

  created() {
    const phone = this.$route.query.phone;

    if (Number(phone)) {
      this.login.username = `${phone}`;
    }
  },

  mounted() {
    const phone = this.$route.query.phone;
    if (Number(phone)) {
      setTimeout(() => {
        this.$refs.password?.focus();
      }, 100);
    }
  },

  methods: {
    ...mapActions("auth", ["LOGIN"]),

    onSend() {
      if (this.$refs.form.validate()) {
        const login = { ...this.login };
        this.isLoading = true;
        this.LOGIN(login)
          .then((msg) => {
            this.isLoading = false;

            if (!msg) {
              this.message = msg;
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    onSubmit() {
      this.onSend();
      return false;
    },

    onOpen(url) {
      this.$router.push(`${url}?phone=${this.login.username}`);
    },

    toPassword() {
      if (this.login.password) {
        this.onSend();
      } else {
        this.$refs.password.focus();
      }
    },
  },
};
</script>

<template>
  <div >
    <auth-template>
      <v-row>
        <v-col cols="12" md="6" xl="5" style="display: flex;justify-content: center;">
          <div class="-content">
            <div
              class="login-title"
              v-text="
                $t(
                  `Dear applicant, to enter the system, enter your phone number:`
                )
              "
            />

            <div class="fields-wrap">
              <v-form @submit.prevent="onSubmit" ref="form" lazy-validation>
                <div class="pb-1">{{ $t("Phone number") }}</div>

                <tel-input-auth
                  :error="!!message"
                  @clear="login.password = null"
                  @enter="toPassword"
                  v-model="login.username"
                  required
                  :disabled="isLoading"
                  autofocus
                  clearable
                />

                <div class="pb-1">{{ $t("Password") }}</div>

                <v-text-field
                  ref="password"
                  id="password"
                  :disabled="isLoading"
                  placeholder=""
                  :type="isShow ? 'text' : 'password'"
                  :append-icon="isShow ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="isShow = !isShow"
                  @focus="message = null"
                  outlined
                  v-model="login.password"
                  required
                  :rules="[$rules.required]"
                  @keyup.enter="onSend"
                />
              </v-form>

              <div class="d-flex pt-2">
                <v-spacer />
                <v-btn
                  @click="onOpen('/forgot-password')"
                  text
                  class="transform-none"
                  :disabled="isLoading"
                  color="rgba(0, 79, 115, 0.5);"
                >
                  {{ $t("Forgot password?") }}
                </v-btn>
              </div>

              <div class="-actions">
                <div style="flex: 1" class="pr-sm-2">
                  <v-btn
                    :loading="isLoading"
                    @click="onSend"
                    color="accent"
                    :disabled="isLoading"
                    class="transform-none w-100"
                  >
                    {{ $t("Continue") }}
                  </v-btn>
                </div>
                <div class="d-block d-sm-none pt-4" />
                <div style="flex: 1" class="pl-sm-2">
                  <v-btn
                    @click="onOpen('/sign-up')"
                    class="transform-none w-100"
                    color="accent"
                    :disabled="isLoading"
                  >
                    {{ $t("Sign up") }}
                  </v-btn>
                </div>
              </div>

              <div class="-description text-justify login-text">
                {{ $t("login.info") }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" md="6" xl="7">
          <img class="login-img" src="../../assets/img/logo.png" alt="" />
        </v-col>
      </v-row>
    </auth-template>
  </div>
</template>

<style lang="scss" scoped>
.-content {
  padding-top: 30px;
  // padding-bottom: 30px;

  @media only screen and(min-width: 960px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.login-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  color: #004f73;

  max-width: 487px;
}

.login-text{
  padding-bottom: 0!important;
}

.fields-wrap {
  padding-top: 30px;
  max-width: 400px;

  .-actions {
    padding-top: 25px;
    display: flex;

    @media only screen and(max-width:599px) {
      flex-direction: column;
    }
  }

  .-description {
    padding-top: 23px;

    font-size: 12px;
    line-height: 150%;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #004f73;

    padding-bottom: 40px;
  }
}

.login-img {
  width: 55%;
  margin-left: 70px;
  margin-top: 30px;
  margin-bottom: 50px;
}
</style>
