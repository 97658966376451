var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(!_vm.isRejected && !_vm.isAccepted && !_vm.isSubmitted)?_c('div',{staticClass:"-download-guide",domProps:{"innerHTML":_vm._s(
      _vm.$t(
        `Download the application guide <a href='{0}' target='_blank'> here </a>.`,
        { 0: 'https://www.youtube.com/watch?v=y2LHYUzUC5E' }
      )
    )}}):_c('div',{staticClass:"-status-wrap"},[_c('div',{staticClass:"-status",style:({
        backgroundColor: _vm.applicationStatus.color,
        textAlign: 'center',
      })},[_vm._v(" "+_vm._s(_vm.$t("Status"))+": "+_vm._s(_vm.applicationStatus.name)+" ")])]),_c('application-add',{attrs:{"isReadonly":_vm.isReadonly}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }