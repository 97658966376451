<script>
import { mapActions, mapGetters } from "vuex";

import appStatuses from "@/service/app-statuses.js";

import AdminAppShow from "@/components/AdminAppShow.vue";
import AdminAppShowEdit from "@/components/AdminAppShowEdit.vue";

import AdminAppHistory from "@/components/AdminAppHistory.vue";

import AppConfirm from "@/components/modals/app-confirm.vue";

export default {
  components: {
    AdminAppShow,
    AdminAppShowEdit,
    AdminAppHistory,
    AppConfirm,
  },

  data: () => ({
    tabs: {
      personal: "Personal info",
      edit: "Edit",
      history: "Application history",
    },

    tab: "Personal info",

    loading: false,

    deleteDialog: false,
  }),

  computed: {
    ...mapGetters("admin-applications", ["GET_APPLICATION"]),
    application: (vm) => vm.GET_APPLICATION,

    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,

    isSupperAdmin: (vm) =>
      ["ADMIN_SUPER", "ADMIN_BRANCH_SUPERVISOR"].includes(vm.user.role),

    applicationStatus() {
      const status =
        appStatuses.find((x) => x.id === this.application.status) || {};

      return {
        name: status[`name-${this.$i18n.locale}`],
        color: color(status.id),
      };

      function color(statusName) {
        switch (statusName) {
          case "ACCEPTED":
            return "#26d317";
          case "REJECTED":
            return "red";
          case "SUBMITTED":
            return "#d8d813";
          default:
            return null;
        }
      }
    },
  },

  created() {
    const id = this.$route.params.id;
    if (id !== this.application.id) {
      this.INIT_BY_ID({ id }).then((status) => {
        if (!status) {
          this.$router.replace("/admin");
        }
      });
    }
  },

  methods: {
    ...mapActions("admin-applications", ["INIT_BY_ID", "DELETE_APPLICATION"]),

    ...mapActions("admin-application", ["INIT_APPLICATION", "INIT_HISTORY"]),

    onEdit() {
      this.loading = true;
      this.INIT_APPLICATION(this.application).then(() => {
        this.loading = false;
        this.tab = this.tabs.edit;
      });
    },

    onDelete() {
      this.loading = true;
      this.DELETE_APPLICATION(this.application).then((status) => {
        this.loading = false;
        if (status) {
          this.$router.replace("/admin");
        }
      });
    },

    onHistory() {
      this.loading = true;

      const params = {
        applicationId: this.application.id,
      };

      this.INIT_HISTORY(params).then((status) => {
        this.loading = false;
        if (status) {
          this.tab = this.tabs.history;
        }
      });
    },
    onAllowance() {
      const id = this.application.id;
      this.$router.push(`${`/admin-allowance/${id}`}`);
    },
    onResult() {
      const id = this.application.id;
      this.$router.push(`${`/admin-result/${id}`}`);
    },
  },
};
</script>

<template>
  <div class="pb-5">
    <app-confirm
      v-model="deleteDialog"
      :text="$t('Do you really want to delete?')"
      @accept="onDelete"
    />

    <div class="-status-wrap pb-5">
      <div
        class="-status"
        :style="{ backgroundColor: applicationStatus.color }"
      >
        {{ $t("Status") }}: {{ applicationStatus.name }}
      </div>
    </div>

    <v-container class="-wrap">
      <div class="pa-5">
        <div>
          <div class="title pb-2 text-center accent--text" v-text="$t(tab)" />

          <div class="title-actions pb-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  elevation="0"
                  color="accent"
                  @click="tab = tabs.personal"
                  :outlined="tab !== tabs.personal"
                  class="px-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-badge-account-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Personal info") }}</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="accent"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  class="px-0 ml-5"
                >
                  <v-icon> mdi-file-pdf-outline </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Download pdf") }}</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="accent"
                  @click="onEdit"
                  :outlined="tab !== tabs.edit"
                  v-bind="attrs"
                  v-on="on"
                  class="px-0 ml-5"
                >
                  <v-icon> mdi-file-document-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Edit") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isSupperAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="accent"
                  :outlined="tab !== tabs.history"
                  v-bind="attrs"
                  v-on="on"
                  @click="onHistory"
                  class="px-0 ml-5"
                >
                  <v-icon> mdi-history</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Application history") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="onAllowance"
                  class="px-0 ml-5"
                >
                  <v-icon> mdi-alpha-a-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Allowance") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isSupperAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="onResult"
                  class="px-0 ml-5"
                >
                  <v-icon> mdi-alpha-r-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Result") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-if="isSupperAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  large
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="px-0 ml-5"
                  @click="deleteDialog = true"
                >
                  <v-icon> mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Delete") }}</span>
            </v-tooltip>
          </div>

          <div class="position-relative">
            <v-progress-linear
              top
              absolute
              :active="loading"
              indeterminate
              posi
            />
          </div>
          <div class="text-center">
            <img src="@/assets/icons/devider.svg" alt="" />
          </div>
        </div>

        <div class="pt-5">
          <admin-app-show v-if="tab === tabs.personal" />
          <admin-app-show-edit v-else-if="tab === tabs.edit" />
          <admin-app-history v-else-if="tab === tabs.history" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.-status-wrap {
  display: flex;
  justify-content: center;

  .-status {
    padding: 8px 5px;
    border-radius: 10px;
    max-width: 320px;
    width: 100%;
    text-align: center;
    cursor: default;

    color: white;
  }
}

.-wrap {
  background: rgba(154, 154, 154, 0.2);
  border-radius: 10px;

  .title-actions {
    .v-btn {
      min-width: 44px;
    }
  }
}
</style>
