import { render, staticRenderFns } from "./ChooseLocale.vue?vue&type=template&id=247c0651&scoped=true"
import script from "./ChooseLocale.vue?vue&type=script&lang=js"
export * from "./ChooseLocale.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247c0651",
  null
  
)

export default component.exports