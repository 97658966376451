import Vue from "vue";
const vm = Vue.prototype;
import axios from "axios";
import store from "../store";
import i18n from "../i18n";

import sysConfig from "../utils/staticConfig.js";

import router from "../router";

interceptor(axios);

const $getImage = async (url) => {
  try {
    const conf = {
      responseType: "arraybuffer",
      headers: {},
    };

    const token = vm.$cookies.get("token");
    if (token) conf.headers.Authorization = `Bearer ${token}`;

    const response = await axios.get(url, conf);

    const fromCharCode = (data, byte) => data + String.fromCharCode(byte);
    const image = btoa(new Uint8Array(response.data).reduce(fromCharCode, ""));
    Buffer.from(response.data, "base64");
    return `data:image/png;base64,${image}`;
  } catch {
    return null;
  }
};

const $getBlob2 = async (url) => {
  try {
    const conf = {
      responseType: "arraybuffer",
      headers: {},
    };

    const token = vm.$cookies.get("token");
    if (token) conf.headers.Authorization = `Bearer ${token}`;

    return await axios.get(url, conf);
  } catch {
    return null;
  }
};

const $get = async (url) => {
  try {
    return (await axios.get(url)).data;
  } catch {
    return null;
  }
};

const $post = async (url, param) => {
  try {
    return (await axios.post(url, param)).data;
  } catch {
    return null;
  }
};

const $put = async (url, param) => {
  try {
    return (await axios.put(url, param)).data;
  } catch {
    return null;
  }
};

const $delete = async (url, param) => {
  try {
    return (await axios.delete(url, param)).data;
  } catch {
    return null;
  }
};

vm.$axios = { $getBlob2, $getImage, $get, $post, $put, $delete, ...axios };

function interceptor() {
  axios.defaults.baseURL = `${sysConfig.serverUrl}${sysConfig.apiVersion}`;

  axios.interceptors.request.use(
    (req) => {
      const token = vm.$cookies.get("token");

      if (token) req.headers.Authorization = `Bearer ${token}`;

      req.headers["Accept-Language"] = i18n.locale;

      return req;
    },

    (config) => {
      const token = vm.$cookies.get("token");

      if (token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },

    (error) => {
      onError(error?.response?.status);

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,

    (error) => {
      onError(error?.response?.status);
      showError(error);

      return Promise.reject(error);
    }
  );
}

function onError(status) {
  if ((status == 401 || status == 403) && router.currentRoute.path != "/") {
    vm.$cookies.remove("token");
    router.replace("/");
  }
}

function showError(error) {
  let msg = null;
  if (error?.response) {
    const res = error.response || {};
    const data = res.data || {};
    const url = res.config.url;
    const currentRoute = router.currentRoute.path;

    if (url == "common/member" && currentRoute == "/") return;

    msg = i18n.t("Internal error. (code-{0})", { 0: res.status });

    if (data.code == 499) {
      const obj = data?.meta || {};

      const keys = Object.keys(obj);
      if (keys.length) {
        msg = "";
        keys.forEach((key, index) => {
          const txt = vm.$toArray(obj[key]).join("<br/>");
          msg += index == 0 ? txt : `<br/> ${txt}`;
        });
      }
    } else if (data.error_description) {
      msg = data.error_description;
    }
  } else {
    msg = i18n.t("No connection to server");
  }

  store.dispatch("SET_SNACKBAR", { text: msg, color: "error" });
}
