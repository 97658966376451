import Vue from "vue";
import $i18n from "../i18n";

import checkApp from "../service/check-application.js";
import fileTypes from "../service/file-types.js";
import toDate from "../service/to-date.js";

const vm = Vue.prototype;

export const state = () => ({
  application: checkApp(),
  disabilitys: [],
  countrys: [],
  regions: [],
  citys: [],
  schoolRegions: [],
  schoolCitys: [],
  branches: [],
  educationSchools: [],
  educationFacultys: [],
  educationTypes: [],
  educationLanguages: [],
  schoolTypes: [],
  certificates: [],
  additionalFaculties: [],
  historys: [],
});

export const mutations = {
  INIT_APPLICATION(state, payload) {
    state.application = payload;
  },

  SET_APPLICATION(state, payload) {
    state.application = payload || checkApp();
  },

  INIT_DISABILITYS(state, payload) {
    state.disabilitys = vm.$toArray(payload);
  },

  INIT_COUNTRYS(state, payload) {
    state.countrys = vm.$toArray(payload);
    state.regions = [];
    state.citys = [];
  },

  INIT_REGIONS(state, payload) {
    state.regions = vm.$toArray(payload);
    state.citys = [];
  },

  INIT_CITYS(state, payload) {
    state.citys = vm.$toArray(payload);
  },

  INIT_SCHOOL_REGIONS(state, payload) {
    state.schoolRegions = vm.$toArray(payload);
    state.schoolCitys = [];
  },

  INIT_SCHOOL_CITYS(state, payload) {
    state.schoolCitys = vm.$toArray(payload);
  },

  INIT_BRANCHES(state, payload) {
    state.branches = vm.$toArray(payload);
    state.educationSchools = [];
    state.educationFacultys = [];
    state.educationTypes = [];
  },

  INIT_EDUCATION_SCHOOLS(state, payload) {
    state.educationSchools = vm.$toArray(payload);
    state.educationFacultys = [];
    state.educationTypes = [];
  },

  INIT_EDUCATION_FACULTYS(state, payload) {
    state.educationFacultys = vm.$toArray(payload);
    state.educationTypes = [];
  },

  INIT_EDUCATION_TYPES(state, payload) {
    state.educationTypes = vm.$toArray(payload);
    state.EducationLanguages = [];
  },

  INIT_EDUCATION_LANGUAGES(state, payload) {
    state.educationLanguages = vm.$toArray(payload);
  },

  INIT_SCHOOL_TYPES(state, payload) {
    state.schoolTypes = vm.$toArray(payload);
  },

  INIT_CERTIFICATES(state, payload) {
    state.certificates = vm.$toArray(payload);
  },

  INIT_ADDITIONAL_FACULTIES(state, { data, initial }) {
    const items = vm.$toArray(data);
    state.additionalFaculties = items;

    let count = items.length < 8 ? items.length : 8;

    if (initial && state.application.additionalFaculty.length) {
      count -= state.application.additionalFaculty.length;
      for (let index = 0; index < count; index++) {
        state.application.additionalFaculty.push({ id: null });
      }
    } else {
      state.application.additionalFaculty = [];
      for (let index = 0; index < count; index++) {
        state.application.additionalFaculty.push({ id: null });
      }
    }
  },

  SET_APPLICATION_PHOTO(state, payload) {
    const obj = { ...state.application };
    if (payload) {
      const { param, id } = payload;

      obj[param] = `/api/v1/admin/application/file/${id}`;
    }

    state.application = obj;
  },

  INIT_HISTORY(state, payload) {
    state.historys = vm.$toArray(payload);
  },
};

export const actions = {
  async INIT_APPLICATION({ state, dispatch, commit }, application) {
    const app = application;
    commit("INIT_APPLICATION", app);

    await dispatch("INIT_DISABILITYS");
    await dispatch("INIT_COUNTRYS");
    await dispatch("INIT_SCHOOL_REGIONS");
    await dispatch("INIT_BRANCHES");
    await dispatch("INIT_EDUCATION_SCHOOLS", { branchId: app.branch.id });
    await dispatch("INIT_SCHOOL_TYPES");
    await dispatch("INIT_CERTIFICATES");
    await dispatch("INIT_REGIONS", { countryId: app.country.id });
    await dispatch("INIT_CITYS", { regionId: app.region.id });
    await dispatch("INIT_SCHOOL_CITYS", { regionId: app.region.id });
    await dispatch("INIT_EDUCATION_FACULTYS", { id: app.educationSchool.id });
    await dispatch("INIT_EDUCATION_TYPES", { id: app.educationFaculty.id });
    await dispatch("INIT_EDUCATION_LANGUAGES", {
      typeId: app.educationType.id,
      facultyId: app.educationFaculty.id,
    });

    if (app.educationLanguage.id) {
      const langs = state.educationLanguages;
      const lang = langs.find(
        (x) => x.language.id === app.educationLanguage.id
      );

      if (lang) {
        app.scienceComplex = lang.scienceComplex || {};

        await dispatch("INIT_ADDITIONAL_FACULTIES", {
          schoolId: app.educationSchool.id,
          scienceComplexId: app.scienceComplex.id,
          facultyId: app.educationFaculty.id,
          typeId1: app.educationType.id,
          initial: true,
        });
      }
    }
  },

  SET_APPLICATION({ commit }, payload) {
    commit("SET_APPLICATION", payload);
  },

  async SAVE_APPLICATION({ commit, dispatch }, application) {
    const params = { ...application };
    const url = "admin/application";
    params.birthDate = toDate(params.birthDate);
    const data = await vm.$axios.$post(url, params);
    const status = data != null;
    if (status) {
      application.status = "SUBMITTED";
      commit("SET_APPLICATION", application);

      dispatch(
        "SET_SNACKBAR",
        {
          text: $i18n.t("Successfully sent!"),
          color: "success",
        },
        { root: true }
      );
    }

    return status;
  },

  async INIT_DISABILITYS({ commit }) {
    const payload = await vm.$axios.$get("admin/disability/all"); //--

    commit("INIT_DISABILITYS", payload);
  },

  async INIT_SCHOOL_TYPES({ commit }) {
    const payload = await vm.$axios.$get("admin/school-type/all");
    commit("INIT_SCHOOL_TYPES", payload);
  },

  async INIT_CERTIFICATES({ commit }) {
    const url = "admin/foreign-language-proficiency/all"; //--
    const payload = await vm.$axios.$get(url);
    commit("INIT_CERTIFICATES", payload);
  },

  async INIT_COUNTRYS({ commit }) {
    const payload = await vm.$axios.$get("admin/country/all");
    commit("INIT_COUNTRYS", payload);
  },

  async INIT_REGIONS({ commit }, { countryId }) {
    commit("INIT_REGIONS", []);
    if (countryId) {
      const url = `admin/region/all/${countryId}`;
      const payload = await vm.$axios.$get(url);
      commit("INIT_REGIONS", payload);
    }
  },

  async INIT_CITYS({ commit }, { regionId }) {
    commit("INIT_CITYS", []);
    if (regionId) {
      const url = `admin/city/all/${regionId}`;
      const payload = await vm.$axios.$get(url);
      commit("INIT_CITYS", payload);
    }
  },

  async INIT_SCHOOL_REGIONS({ commit }) {
    commit("INIT_SCHOOL_REGIONS", []);

    const url = `admin/region/all/${1}`;
    const payload = await vm.$axios.$get(url);
    commit("INIT_SCHOOL_REGIONS", payload);
  },

  async INIT_SCHOOL_CITYS({ commit }, { regionId }) {
    commit("INIT_SCHOOL_CITYS", []);

    if (regionId) {
      const url = `admin/city/all/${regionId}`;

      const payload = await vm.$axios.$get(url);
      commit("INIT_SCHOOL_CITYS", payload);
    }
  },

  async INIT_BRANCHES({ commit }) {
    const payload = await vm.$axios.$get("admin/education/branch/all");
    commit("INIT_BRANCHES", payload);
  },

  async INIT_EDUCATION_SCHOOLS({ commit }, { branchId }) {
    commit("INIT_EDUCATION_SCHOOLS", []);
    if (branchId) {
      const url = `admin/education/branch/${branchId}/school/all`;
      const payload = await vm.$axios.$get(url);
      commit("INIT_EDUCATION_SCHOOLS", payload);
    }
  },

  async INIT_EDUCATION_FACULTYS({ commit }, { id }) {
    commit("INIT_EDUCATION_FACULTYS", []);

    if (id) {
      const url = `admin/education/school/${id}/faculty/all`;
      const payload = await vm.$axios.$get(url);
      commit("INIT_EDUCATION_FACULTYS", payload);
    }
  },

  async INIT_EDUCATION_TYPES({ commit }, { id }) {
    commit("INIT_EDUCATION_TYPES", []);

    if (id) {
      const url = `admin/education/faculty/${id}/type/all`;
      const payload = await vm.$axios.$get(url);
      commit("INIT_EDUCATION_TYPES", payload);
    }
  },

  async INIT_EDUCATION_LANGUAGES({ commit }, { typeId, facultyId }) {
    commit("INIT_EDUCATION_LANGUAGES", []);

    if (typeId && facultyId) {
      const url = `admin/education/faculty/${facultyId}/${typeId}/language/all`;
      const payload = await vm.$axios.$get(url);

      commit("INIT_EDUCATION_LANGUAGES", payload);
    }
  },

  async INIT_ADDITIONAL_FACULTIES(
    { commit },
    { scienceComplexId, facultyId, schoolId, typeId1, initial }
  ) {
    if (!initial) {
      commit("INIT_ADDITIONAL_FACULTIES", []);
    }

    if (scienceComplexId && facultyId && typeId1 && schoolId) {
      const url = `admin/education/school/${schoolId}/faculty/${facultyId}/${scienceComplexId}/${typeId1}/additional-faculties/all`;
      const payload = await vm.$axios.$get(url);

      commit("INIT_ADDITIONAL_FACULTIES", { data: payload, initial });
    }
  },

  async UPLOAD_FILE({ commit }, { file, param, applicationId }) {
    const fileType = fileTypes[param];

    if (fileType) {
      const form = new FormData();
      form.append("file", file);

      const url = `admin/application/file/${fileType}/${applicationId}`;
      const data = await vm.$axios.$post(url, form);

      if (data) {
        commit("SET_APPLICATION_PHOTO", { id: data, param });
      }
    }
  },

  async GET_FILE_API(ctx, { url }) {
    if (url) {
      return await vm.$axios.$getImage(url);
    } else return null;
  },

  async DELETE_FILE_API({ commit }, { param, applicationId }) {
    const fileType = fileTypes[param];

    if (fileType) {
      const url = `admin/application/file/${fileType}/${applicationId}`;
      const status = await vm.$axios.$delete(url);

      if (status != null) {
        commit("SET_APPLICATION_PHOTO", { url: null, param });
      }
    }
  },

  async INIT_HISTORY({ commit }, { applicationId }) {
    let status = false;

    commit("INIT_HISTORY", []);

    if (applicationId) {
      const url = `/admin/application-history/${applicationId}`;
      const payload = await vm.$axios.$get(url);

      status = Array.isArray(payload);

      commit("INIT_HISTORY", payload);
    }

    return status;
  },
};

export const getters = {
  GET_APPLICATION: (state) => state.application,
  GET_DISABILITYS: (state) => state.disabilitys,
  GET_COUNTRYS: (state) => state.countrys,
  GET_REGIONS: (state) => state.regions,
  GET_CITYS: (state) => state.citys,
  GET_SCHOOL_REGIONS: (state) => state.schoolRegions,
  GET_SCHOOL_CITYS: (state) => state.schoolCitys,
  GET_BRANCHES: (state) => state.branches,
  GET_EDUCATION_SCHOOLS: (state) => state.educationSchools,
  GET_EDUCATION_FACULTYS: (state) => state.educationFacultys,
  GET_EDUCATION_TYPES: (state) => state.educationTypes,
  GET_EDUCATION_LANGUAGES: (state) => state.educationLanguages,
  GET_SCHOOL_TYPES: (state) => state.schoolTypes,
  GET_CERTIFICATES: (state) => state.certificates,
  GET_ADDITIONAL_FACULTIES: (state) => state.additionalFaculties,
  GET_HISTORYS: (state) => state.historys,
  GET_RUXSATNOMA: (state) => state.ruxsatnoma,
};
