<script>
import { mapGetters, mapActions } from "vuex";
import appConfirm from "./modals/app-confirm.vue";
import compressImage from "../service/compress-image.js";

export default {
  components: { appConfirm },
  props: {
    param: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: () => false,
    },

    readonly: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    uniqId: Date.now().toString(36) + Math.random().toString(36).substr(2),
    image: null,
    loading: false,
    deleteDialog: false,
  }),

  computed: {
    ...mapGetters("admin-application", ["GET_APPLICATION"]),
    application: (vm) => vm.GET_APPLICATION,
    imagePath: (vm) => vm.application[vm.param],
    isAccepted() {
      const status = this.application.status;
      return status == "ACCEPTED";
    },

    isSubmitted() {
      const status = this.application.status;
      return status == "SUBMITTED";
    },
  },

  watch: {
    imagePath() {
      this.initImage();
    },
  },

  created() {
    this.initImage();
  },

  methods: {
    ...mapActions("admin-application", [
      "UPLOAD_FILE",
      "GET_FILE_API",
      "DELETE_FILE_API",
    ]),

    async onChange(event) {
      const files = event?.target?.files || [];
      if (files?.length) {
        const file = await compressImage(files[0]);

        if (file) {
          this.image = null;
          this.loading = true;
          const param = {
            file,
            param: this.param,
            applicationId: this.application.id,
            imagePath: this.imagePath,
          };
          await this.UPLOAD_FILE(param).then(() => {
            this.initImage();
          });
        }
      }
    },

    initImage() {
      if (this.imagePath) {
        this.loading = true;
        this.GET_FILE_API({ url: this.$file(this.imagePath) }).then((res) => {
          this.image = res;
          this.loading = false;
        });
      }
    },

    onDelete() {
      const params = {
        param: this.param,
        applicationId: this.application.id,
      };

      this.DELETE_FILE_API(params).then((res) => {
        if (res != null) {
          this.image = null;
        }
      });
    },
  },
};
</script>

<template>
  <div class="-wrap">
    <app-confirm
      v-model="deleteDialog"
      :text="$t('Do you really want to delete?')"
      @accept="onDelete"
    />

    <div class="-image">
      <div class="h-100" v-if="imagePath">
        <div v-if="image" class="images" v-viewer>
          <div
            v-if="!isAccepted && !isSubmitted"
            v-ripple
            @click="deleteDialog = true"
            class="-icon"
            hidden
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </div>
          <img class="cursor-pointer" :src="image" alt="" />
        </div>
        <v-skeleton-loader
          v-else-if="loading"
          class="h-100 w-100"
          type="image"
        />
      </div>

      <label
        v-ripple
        class="-card"
        :class="required && 'error--text'"
        v-else-if="!readonly"
        :for="uniqId"
      >
        <img width="45" src="../assets/icons/upload-file.svg" />
        <div v-text="$t('Upload an image')" />
      </label>
      <input
        @change="onChange"
        type="file"
        :id="uniqId"
        accept="image/x-png,image/jpeg"
      />
    </div>

    <div>
      <div style="max-width: 400px" class="text-center">
        <label
          class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default accent cursor-pointer"
          :for="uniqId"
          v-ripple
        >
          <span class="v-btn__content" v-text="$t('Change the image')" />
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.images {
  position: relative;
  .-icon {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: #ebebeb;
    padding: 10px;
    border-bottom-right-radius: 70%;
    cursor: pointer;
  }
}

.-wrap {
  height: 100%;

  .-image {
    max-width: 400px;
    width: 100%;
    height: 80%;

    > * {
      width: 100%;
    }

    overflow: hidden;

    img {
      height: 250px;
    }
  }
}

.-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  border: 1.5px solid #6c6c6c;
  border-radius: 5px;
  cursor: pointer;

  > div {
    padding-top: 5px;
  }

  &.error--text {
    border: 1.5px solid #ff00006b;
    box-shadow: inset 0 0 10px #ff00007d;
  }
}

input {
  display: none;
}
</style>
