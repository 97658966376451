<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    message: {
      text: null,
      type: "ALL",
    },

    max100: (v) => (v && `${v}`.length <= 160) || "",
  }),

  methods: {
    ...mapActions("admin-message", ["SEND_MESSAGE"]),

    onSend() {
      if (this.$refs.form.validate()) {
        this.SEND_MESSAGE(this.message).then((status) => {
          if (status != null) {
            this.$store.dispatch("SET_SNACKBAR", {
              text: this.$t("Message send success!"),
              color: "success",
            });

            this.message = { text: null, type: "ALL" };
            this.$nextTick(() => {
              this.$refs.form.reset();
            });
          }
        });
      }
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title py-5" v-text="$t('Send message')" />
        <v-form @submit.prevent="() => false" ref="form" lazy-validation>
          <v-textarea
            :label="$t('Message')"
            v-model="message.text"
            outlined
            autofocus
            counter="160"
            required
            :rules="[$rules.required, max100]"
          />
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-radio-group v-model="message.type" row>
          <v-radio :label="$t('All')" value="ALL"></v-radio>
          <v-radio :label="$t('To Accepted')" value="ACCEPTED"></v-radio>
          <v-radio :label="$t('To Rejected')" value="REJECTED"></v-radio>
          <v-radio :label="$t('To Migrated')" value="MIGRATED"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-spacer />
          <v-btn @click="onSend" color="accent">
            {{ $t("Send") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
