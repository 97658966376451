<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<template>
  <v-container>
    <strong class="title_text"
      ><h2>{{ $t("help_title1") }}</h2></strong
    >
    <ol>
      <li style="text-align: justify" class="pt-5">
        {{ $t("help_doc1") }}
      </li>
      <li style="text-align: justify" class="pt-5">
        {{ $t("help_doc2") }}
      </li>
      <li style="text-align: justify" class="pt-5">
        {{ $t("help_doc3") }}
      </li>
      <li style="text-align: justify" class="pt-5">
        {{ $t("help_doc4") }}
      </li>
      <li style="text-align: justify" class="pt-5">
        {{ $t("help_doc5") }}
      </li>
    </ol>
  </v-container>
</template>
<style lang="scss" scoped>
.title_text {
  text-align: left;
  margin: 20px;
}
</style>
