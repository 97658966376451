<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    types: {},
    langs: {},
    sciencs: {},
    selectedSciencs: {},

    isInited: false,
  }),

  computed: {
    ...mapGetters("admin-education-types", ["GET_EDUCATION_TYPES_ALL"]),
    ...mapGetters("admin-education-languages", ["GET_EDUCATION_LANGUAGE_ALL"]),

    isType: (vm) => Object.values(vm.types).filter((x) => x).length > 0,
    isLangs: (vm) => Object.values(vm.langs).filter((x) => x).length > 0,
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      const initeds = this.$toArray(this.value);

      if (initeds.length) {
        let types = {};
        let langs = {};
        let selectedSciencs = {};

        for (const ftype of initeds) {
          const type = ftype.type;

          types[type.id] = true;

          for (const flang of ftype.languages) {
            const lang = flang.language;
            langs[`${type.id}-${lang.id}`] = true;
            selectedSciencs[`${type.id}-${lang.id}`] = flang.scienceComplex;
            await this.onChangeLang(true, type, lang, true);
          }
        }

        this.langs = langs;
        this.selectedSciencs = selectedSciencs;

        this.$nextTick(() => {
          this.types = types;
        });
      }

      this.isInited = true;
    },

    ...mapActions("admin-sciences", ["GET_SCIENCES_BY_LANG"]),

    async onChangeLang(status, type, lang, isnLoader) {
      let sciencs = [];
      if (status) {
        if (isnLoader) this.$emit("loading", true);
        sciencs = await this.GET_SCIENCES_BY_LANG({ langId: lang.id });
        if (isnLoader) this.$emit("loading", false);
      }

      this.sciencs[`${type.id}-${lang.id}`] = sciencs;
      this.onUpdateModel();
      this.$nextTick(() => {
        this.sciencs = { ...this.sciencs };
      });
    },

    onUpdateModel() {
      const typeIds = Object.keys(this.types).filter((x) => this.types[x]);

      const types = typeIds.map((typeId) => {
        const type = this.GET_EDUCATION_TYPES_ALL.find((x) => x.id == typeId);
        if (!type) return null;

        const oldFtype =
          this.$toArray(this.value).find((x) => x.type?.id == typeId) || {};

        const langsKeys = Object.keys(this.langs).filter(
          (x) => this.langs[x] && this.getTypeId(x) == type.id
        );
        const langs = langsKeys.map((langKey) => {
          const lang = this.GET_EDUCATION_LANGUAGE_ALL.find(
            (x) => x.id == this.getLangId(langKey)
          );

          const oldFLang =
            this.$toArray(oldFtype.languages).find(
              (x) => x.language.id == lang.id
            ) || {};

          const scienceComplex = this.selectedSciencs[`${type.id}-${lang.id}`];

          return { id: oldFLang.id, language: lang, scienceComplex };
        });

        return {
          id: oldFtype?.id,
          type: type,
          languages: langs,
        };
      });

      this.$emit("input", types);
    },

    getTypeId(str) {
      const index = str.indexOf("-");
      if (index >= 0) {
        return str.substr(0, index);
      } else return null;
    },

    getLangId(str) {
      const index = str.indexOf("-");
      if (index >= 0) {
        return str.substr(index + 1);
      } else return null;
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.height = 0;
      el.style.overflow = "hidden";
    },

    enter(el, done) {
      if (process.browser) {
        const Velocity = require("velocity-animate");
        const delay = el.dataset.index * 150;
        let height = el?.firstChild.offsetHeight || 116;

        if (el.classList.contains("-type-ed")) {
          height += 8;
        }

        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, height: `${height}px` },
            { complete: done }
          );

          setTimeout(() => {
            el.style.height = null;
          }, 600);
        }, delay);
      }
    },

    leave(el, done) {
      if (process.browser) {
        const Velocity = require("velocity-animate");
        const delay = el.dataset.index * 150;
        setTimeout(() => {
          Velocity(el, { opacity: 0, height: 0 }, { complete: done });
        }, delay);
      }
    },
  },
};
</script>

<template>
  <v-row v-if="isInited">
    <v-col
      v-for="type in GET_EDUCATION_TYPES_ALL"
      cols="12"
      :key="`type-${type.id}`"
      class="py-0"
    >
      <v-checkbox
        @change="onUpdateModel"
        required
        :rules="[isType ? true : $rules.required]"
        v-model="types[type.id]"
        :label="type.name"
        hide-details
      />
      <transition
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
        name="staggered-fade"
        :css="false"
      >
        <div v-if="types[type.id]" class="-type-ed">
          <div class="pl-8">
            <div
              v-for="lang in GET_EDUCATION_LANGUAGE_ALL"
              :key="`l-${type.id}-${lang.id}`"
            >
              <v-checkbox
                class="mt-2"
                @change="
                  onChangeLang(langs[`${type.id}-${lang.id}`], type, lang)
                "
                v-model="langs[`${type.id}-${lang.id}`]"
                :label="lang.name"
                hide-details
                required
                :rules="[isLangs ? true : $rules.required]"
              />
              <transition
                @before-enter="beforeEnter"
                @enter="enter"
                @leave="leave"
                name="staggered-fade"
                :css="false"
              >
                <div v-if="$toArray(sciencs[`${type.id}-${lang.id}`]).length">
                  <div class="position-relative">
                    <div class="science-arrow" />
                    <div class="pt-3 pb-2 pl-8" style="max-width: 320px">
                      <v-select
                        :label="$t('Science Complex')"
                        outlined
                        dense
                        hide-details
                        :items="$toArray(sciencs[`${type.id}-${lang.id}`])"
                        v-model="selectedSciencs[`${type.id}-${lang.id}`]"
                        item-text="name"
                        item-value="id"
                        required
                        return-object
                        :rules="[$rules.required]"
                        @change="onUpdateModel"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.science-arrow {
  width: 15px;
  height: 25px;
  position: absolute;
  border-left: 1px dashed;
  border-bottom: 1px dashed;
  top: 6px;
  left: 10px;
}
</style>
