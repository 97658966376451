<script>
import PhoneNumber from "awesome-phonenumber";

import allCountries from "./all-countries";

export default {
  props: {
    value: {
      type: [String, Boolean],
      default: () => false,
    },

    required: {
      type: Boolean,
      default: () => false,
    },

    autofocus: {
      type: Boolean,
      default: () => false,
    },

    clearable: {
      type: Boolean,
      default: () => false,
    },

    dense: {
      type: Boolean,
      default: () => false,
    },

    error: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    countries: [...allCountries],
    activeCountry: allCountries[0],
    phone: null,
  }),

  computed: {
    getMask() {
      const code = this.activeCountry.dialCode || "";
      const iso = this.activeCountry.iso2 || "";
      const example = PhoneNumber.getExample(iso).getNumber("international");
      const mask = example
        ?.replace("+", "")
        ?.replace(code, "")
        ?.replace(/\d/g, "#")
        ?.trim();

      return mask || "";
    },

    maskRule: (vm) => (v) => {
      const value = v?.trim()?.replace(/\s/g, "")?.length;
      const mask = vm.getMask?.replace(/\s/g, "")?.length;
      if (!vm.required) {
        return true;
      } else if (value == mask) {
        return true;
      } else {
        return "";
      }
    },
  },

  watch: {
    value() {
      this.onValue();
    },

    phone(v) {
      const isValid = this.maskRule(v);
      const code = this.activeCountry?.dialCode;

      if (!v) this.$emit("clear");

      if (isValid == true) {
        const num = `${code}${v.replace(/\s/g, "")}`;
        if (this.value != num) {
          this.$emit("input", num);
        }
      }
    },
  },

  created() {
    this.onValue();
  },

  methods: {
    onChangeCountryCode() {
      this.phone = null;
    },

    getCode(v) {
      const r = PhoneNumber(v.includes("+") ? v : `+${v}`).getRegionCode();

      const activeCountry = this.countries.find((x) => x.iso2 == r);
      if (activeCountry) {
        this.activeCountry = activeCountry;
        return activeCountry.dialCode;
      }

      return null;
    },

    onValue() {
      const value = `${this.value || ""}`;

      if (value) {
        const code = this.getCode(value);
        if (code) {
          const phone = value?.replace("+", "")?.replace(code, "");
          if (phone != this.phone) this.phone = phone;
        }
      }
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <div style="flex: 1">
      <v-select
        v-model="activeCountry"
        @change="onChangeCountryCode"
        :items="countries"
        outlined
        item-text="name"
        item-value="iso2"
        return-object
        :dense="dense"
      >
        <template v-slot:selection>
          <div class="d-flex align-center">
            <span :class="activeCountry.iso2.toLowerCase()" class="vti__flag" />
            <span> {{ `+${activeCountry.dialCode}` }}</span>
          </div>
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </div>
    <div style="flex: 3">
      <v-text-field
        :dense="dense"
        outlined
        v-mask="getMask"
        v-model="phone"
        :clearable="clearable"
        :required="required"
        :rules="[required ? $rules.required : true, maskRule]"
        @keyup.enter="$emit('enter')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("./sprite.css");

.vti__flag {
  margin-right: 8px;
}
</style>
