<script>
import { mapActions, mapGetters } from "vuex";

import sizeVue from "@/mixins/size.vue";

export default {
  props: {
    canExport: {
      type: Boolean,
      default: false,
    },
    status: Number,
    branch: Number,
  },

  data: () => ({
    isLoading: false,

    search: null,

    options: {},
    totalItemsCount: 0,
    itemsPerPage: 20,
    page: 1,

    isExport: false,
  }),

  mixins: [sizeVue],

  computed: {
    tableHeight: (vm) =>
      vm.size.width >= 1200 ? vm.size.height - 370 : "auth",

    ...mapGetters("admin-applications", ["GET_APPLICATIONS"]),
    items: (vm) => vm.GET_APPLICATIONS,

    tabStatus: {
      get: (vm) => vm.status,
      set(v) {
        this.$emit("status", v);
      },
    },

    tabBranch: {
      get: (vm) => vm.branch,
      set(v) {
        this.$emit("branch", v);
      },
    },

    paginationCount() {
      return Math.ceil(this.totalItemsCount / this.itemsPerPage);
    },
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },

    tabStatus() {
      this.page = 1;
      this.$nextTick(() => {
        this.getItems();
      });
    },

    tabBranch() {
      this.page = 1;
      this.$nextTick(() => {
        this.getItems();
      });
    },

    isLoading(v) {
      this.$emit("loading", v);
    },
  },

  methods: {
    ...mapActions("admin-applications", [
      "INIT_APPLICATIONS",
      "INIT_BY_ID",
      "EXPORT_EXCEL",
    ]),

    getItems() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.options.status = this.status;
      this.options.branch = this.branch;
      this.options.search = this.search;

      this.INIT_APPLICATIONS(this.options).then((totalItemsCount) => {
        this.totalItemsCount = totalItemsCount;
        this.isLoading = false;
      });
    },

    onExport() {
      this.isExport = true;
      this.EXPORT_EXCEL({ status: this.status, branch: this.branch }).then(
        () => {
          this.isExport = false;
        }
      );
    },

    getName: (item) => item?.name,

    getFullName(item) {
      const lastName = item.lastName || "";
      const firstName = item.firstName || "";
      const middleName = item.middleName || "";
      return `${lastName} ${firstName} ${middleName}`;
    },

    async onOpen({ id }) {
      const status = await this.INIT_BY_ID({ id });
      if (status) {
        window.open(`admin-application-show/${id}`, "_blank");
      }
    },

    onClear() {
      this.search = null;
      this.page = 1;

      this.$nextTick(() => {
        this.getItems();
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="pt-4">
      <div class="search-wrapper">
        <v-text-field
          :placeholder="$t('Search...')"
          append-icon="mdi-magnify"
          v-model="search"
          dense
          class="search-input"
          hide-details
          outlined
          clearable
          @click:append="getItems"
          @keyup.enter="getItems"
          @click:clear="onClear"
        />

        <v-btn
          :loading="isExport"
          v-if="canExport"
          color="accent"
          class="ml-2 transform-none"
          @click="onExport"
        >
          {{ $t("Export to excel") }}
        </v-btn>
      </div>
    </div>

    <v-data-table
      :mobile-breakpoint="0"
      :height="tableHeight"
      :loading="isLoading"
      hide-default-footer
      :headers="
        branch === -1
          ? [
              {
                text: $t('Full name'),
                value: 'fullName',
              },
              {
                text: $t('Date'),
                value: 'registrationDate',
              },
              {
                text: $t('Branch'),
                value: 'branch',
              },
              {
                text: $t('Education direction'),
                value: 'faculty',
              },
              {
                text: $t('Phone'),
                value: 'mobilePhone',
              },
              {
                text: $t('Passport'),
                value: 'passportNumber',
              },
            ]
          : [
              {
                text: $t('Full name'),
                value: 'fullName',
              },
              {
                text: $t('Date'),
                value: 'registrationDate',
              },
              {
                text: $t('Education direction'),
                value: 'faculty',
              },
              {
                text: $t('Phone'),
                value: 'mobilePhone',
              },
              {
                text: $t('Passport'),
                value: 'passportNumber',
              },
            ]
      "
      fixed-header
      :items="items"
      :options.sync="options"
      :server-items-length="totalItemsCount"
      item-key="id"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      class="elevation-0"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            @click="onOpen(item)"
            class="cursor-pointer"
            :key="item.id"
            v-for="item in props.items"
          >
            <td v-text="getFullName(item)" />
            <td v-text="$date(item.registrationDate)" />
            <td v-if="branch === -1" v-text="item.branch" />
            <td v-text="getName(item.faculty)" />
            <td v-text="$tel(item.mobilePhone)" />
            <td v-text="item.passportNumber" />
          </tr>
          <tr v-if="!props.items.length && !isLoading">
            <td :colspan="props.headers.length + 1" class="text-center py-4">
              {{ $t("No data") }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="pt-2 pb-2">
      <v-pagination
        :total-visible="10"
        v-model="page"
        :length="paginationCount"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-wrapper {
  display: flex;

  @media only screen and(max-width: 1299px) {
    padding: 10px 20px;
  }

  @media only screen and(min-width: 1400px) {
    width: 350px !important;
  }

  @media only screen and(min-width: 1300px) {
    width: 350px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
